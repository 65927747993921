import React, { memo } from 'react';
import { Modal, Button } from 'react-bootstrap';
import { Cropper, CircleStencil } from 'react-advanced-cropper'
// import { ImageEditor } from './ImageEditor';
import { getApiUrl, getRessouresUrl } from '../../utils/config';
import { getToken } from '../../session-utils/UserSession';
import { library } from '@fortawesome/fontawesome-svg-core'
import { faEdit } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import defaultProfilePic from '../../assets/images/default_profilepic.png';

import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-advanced-cropper/dist/style.css';
import 'react-advanced-cropper/dist/themes/corners.css';
import './../../assets/css/style.css';
import './img-editor.css';

library.add(faEdit)


async function updateMemberProfilePicture (credentials) {
    return fetch(getApiUrl() + "team/profilepic", {
        method: 'POST',
        headers: {
            'Authorization': 'Bearer ' + getToken()
        },
        body: credentials
    })
    .then(data => data.json())
    .then(data => { return data.member })
    .catch(error => console.log(error))
}

class ImageEditorModal extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            modalIsOpen: false,
            pictureCompleteFile: null,
            pictureImgFile: null,
            cropperRef: React.createRef(null),
            formFileUploadRef: React.createRef(null),
            frameCoordinates: null,
        }
      }

    openEditorModal = () => this.setState({ modalIsOpen: true });
    closeEditorModal = () => { 
        this.setState({ 
            modalIsOpen: false,
            pictureCompleteFile: null,
            pictureImgFile: null,
            cropperRef: React.createRef(null),
            formFileUploadRef: React.createRef(null),
            frameCoordinates: null,
         });
    };
    
    handleAutomaticFormClick = () => {
        this.state.formFileUploadRef.current.click()
    }

    handleImageSelection = e => {
        e.preventDefault();
        
        let filetype = null;
        // Check if received file is actually an image file
        if (e.target.files.length !== 0) {
            filetype = e.target.files[0].type;
            if (filetype !== "image/png" && filetype !== "image/jpeg" && filetype !== "image/jpg") {
                alert("Image file must be a .png, .jpeg or .jpg file !");
                return null;
            }
        }

        if (filetype !== null) {
            // Store image file in state, and open image editor modal
            this.setState({ 
                pictureCompleteFile: e.target.files[0],
                pictureImgFile: URL.createObjectURL(e.target.files[0]) }, 
                () => {
                    
                    this.setState({ modalIsOpen: true });
                });

        }

        // Reset event target value to ''
        // This will retrigger the event, in case the user re-chooses the same input file again
        e.target.value = '';
    }

    onImageCrop = () => {
        if (this.state.cropperRef.current) {
            this.setState({ frameCoordinates: this.state.cropperRef.current.getCoordinates()});
            this.setState({ pictureImgFile: this.state.cropperRef.current.getCanvas()?.toDataURL()} );
        }
    };

    handleImageOnChange = (editedPicture) => {
        this.setState({ pictureImgFile: editedPicture });
    }

    handleImageUpload = async () => {
        if (this.state.cropperRef.current) {
            // console.log("cropperRef.current ", cropperRef.current);
            // console.log("cropperRef.current.getCanvas() ", cropperRef.current.getCanvas());
            // console.log("cropperRef.current.getCanvas()?.toDataURL ", cropperRef.current.getCanvas()?.toDataURL());
      
            if (this.state.cropperRef.current.getCanvas()) {
                if (this.state.cropperRef.current.getCanvas().toDataURL()) {
                    const canvasURL = this.state.cropperRef.current.getCanvas()?.toDataURL();
                    const splitDataURI = canvasURL.split(',');
                    const byteString = splitDataURI[0].indexOf('base64') >= 0 ? atob(splitDataURI[1]) : decodeURI(splitDataURI[1]);
                    const mimeString = splitDataURI[0].split(':')[1].split(';')[0];
        
                    const imageArray = new Uint8Array(byteString.length);
                    for (let i = 0; i < byteString.length; i++)
                        imageArray[i] = byteString.charCodeAt(i);
        
                    const fileImage = new Blob([imageArray], { type: mimeString })
                    
                    
                    const member = {
                        _id: this.props.memberId,
                    };
                    let thingData = new FormData();
            
                    thingData.append('member', JSON.stringify(member));
                    thingData.append('team', fileImage, (this.props.memberFullname.replace(' ', '_')+"_profilepic_"));
                    
                    await updateMemberProfilePicture(thingData);
                    this.setState({ modalIsOpen: false });
                    this.props.loadAll();
                }
            }
        }
        
        // const member = {
        //     _id: this.props.memberId,
        // };
        // let thingData = new FormData();

        // thingData.append('member', JSON.stringify(member));

        // if (this.state.pictureImgFile !== undefined && this.state.pictureImgFile !== null) {
        //     thingData.append('team', this.state.pictureImgFile, (this.props.memberFullname.replace(' ', '_')+"_profilepic_"));
        // } else {
        //     return null;
        // }
        // await updateMemberProfilePicture(thingData);
        // this.setState({ modalIsOpen: false });
        // this.props.loadAll();
    }

    componentWillUnmount() {
        this.setState({ 
            modalIsOpen: false,
            pictureCompleteFile: null,
            pictureImgFile: null,
            cropperRef: React.createRef(null),
            formFileUploadRef: React.createRef(null),
            frameCoordinates: null,
         });
    }

    render() {
        return (
        <>
            <button type="button" className="custom-invisible-btn" onClick={this.handleAutomaticFormClick}>
                <div className="image-circular-interactive-frame">
                    {
                        (this.props.memberProfilePic === undefined || this.props.memberProfilePic === "")
                        ? <img className="image circular" src={defaultProfilePic} alt="" />
                        : <img className="image circular" src={getRessouresUrl()+this.props.memberProfilePic} alt={this.props.memberFullname+"'s profile picture"} />
                    }
                    <figcaption>&nbsp;<FontAwesomeIcon icon={faEdit} /></figcaption>
                </div>
            </button>
            
            <form id="submitImageForm" encType="multipart/form-data" style={{ display: "none" }}>
                <input type="file"
                    ref={this.state.formFileUploadRef}
                    name="team"
                    accept="image/*"
                    onChange={e => this.handleImageSelection(e)} />
            </form>

            <Modal 
                show={this.state.modalIsOpen}
                onHide={this.closeEditorModal}
                size="lg"
                backdrop="static"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                >
                <Modal.Header 
                        className="card-style-steel-texture"
                        id="contained-modal-title-vcenter" 
                        closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        Edit profile picture
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body className="card-style-steel-texture">
                    <Cropper
                        ref={this.state.cropperRef}
                        src={this.state.pictureImgFile}
                        stencilSize={{
                            width: 280,
                            height: 280
                        }}
                        stencilComponent={CircleStencil}
                        stencilProps={{
                            previewClassName: 'preview'
                        }}
                        onChange={this.onCroppedFrameChange}
                    />
                    {/* <ImageEditor 
                        imgSrc={this.state.pictureImgFile} 
                        handleImageOnChange={this.handleImageOnChange}
                    /> */}
                </Modal.Body>
                <Modal.Footer className="card-style-steel-texture">
                    <Button variant="secondary"
                        style={{ width: '100px' }}
                        onClick={this.closeEditorModal}>
                        Close
                    </Button>
                    <Button variant="warning"
                        style={{ width: '100px' }}
                        onClick={this.handleImageUpload} >
                        Save
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    )}

}

export default memo(ImageEditorModal);