import React, { Component, memo } from 'react';
import NewsCard from './NewsCard';
import { getApiUrl } from '../../utils/config';
import CircularLoading from '../../components/circular-loading/CircularLoading';

class NewsComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      news: [],
      isLoaded: false
    }
  }

  componentDidMount() {
    fetch(getApiUrl() + 'news', {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json'
      },
      mode: 'cors',
      cache: 'default',
      credentials: 'omit'

    })
      .then(res => res.json())
      .then(data => {
        this.setState({ 
          isLoaded: true, 
          news: this.sortNewsByPublicationDate(data.news)
        });
      })
      .catch(error => console.log(error))
  }

  sortNewsByPublicationDate = (news) => {
    return news.sort((a, b) => (new Date(a.publicationDate) > new Date(b.publicationDate)) ? -1 : ((new Date(a.publicationDate) < new Date(b.publicationDate)) ? 1 : 0))
  }

  render() {
    if (!this.state.isLoaded) {
      return <div><CircularLoading /></div>;
    } else if (this.state.news.length === 0) {
      return <p>No news articles found</p>;
    } else {
      return (  <>
                  {this.state.news.map(newsarticle => (
                    <NewsCard   key={newsarticle._id} 
                                data={newsarticle} />
                  ))}
                </>);
    }
  }

}

function News() {
  return (
    <div>
      <h2>News</h2>
      <br />
      <NewsComponent />
    </div>
  );
}

export default memo(News);