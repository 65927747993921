import React, { memo } from 'react';
import MailingListModal from './MailingListModal';

import './../../../assets/css/style.css';

const MailingListCard = (props) => {
    const subscriber = props.data;

    return (
        <div className="custom-card-mailinglist card-style-steel-texture card-shadow">
            <div className="text-container-mailinglist">
                {/* Required fields */}
                <p>Subscriber's mail address: <a href={"mailto:"+ subscriber.email }>{subscriber.email}</a></p>
                <p>Subscriber's full name: 
                    {subscriber.fullname
                        ? subscriber.fullname
                        : "--"
                    }
                </p>
            </div>
            <div className="buttons-container-mailinglist">
                <MailingListModal   id={subscriber._id}
                                    loadAll={props.loadAll}
                                    subscriber={subscriber}
                                    action="edit" />

                <MailingListModal   id={subscriber._id}
                                    loadAll={props.loadAll}
                                    subscriber={subscriber}
                                    action="remove" />
            </div>
        </div>);

};

export default memo(MailingListCard);
