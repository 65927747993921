import React from "react";
import './index.css';

function LoadingPage() {
  return (
  <div className="loading-page">
    <h1 className="loading-prompt">Loading page...</h1>
  </div>
  );
}

export default LoadingPage;