import React, { memo } from 'react';
import { prettyPrintDateTime } from '../../../utils/config';
import { getApiUrl } from '../../../utils/config';
import { getToken } from '../../../session-utils/UserSession';
import { Button } from 'react-bootstrap';
import ContactMessageModal from './ContactMessageModal';
import {    faHourglassHalf, 
            faArrowRotateLeft, 
            faCheck, 
            faCircleCheck   } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import './../../../assets/css/style.css';

async function alterMessageState(credentials, callback) {
    return fetch(getApiUrl() + "messages/alterstate", {
        method: 'POST',
        headers: {
            'Authorization': 'Bearer ' + getToken(),
            'Content-Type': 'application/json'
        },
        mode: 'cors',
        cache: 'default',
        body: JSON.stringify({ _id: credentials })
    })
    .then(data => { data.json(); callback() })
    .catch(error => console.log(error))
}

const ContactMessageCard = (props) => {
    const contactMessage = props.data;

    return (
        <div className="custom-card viewer card-style-steel-texture card-shadow">
            <div className="text-container">
                <div>
                    <p>{contactMessage.pending
                        ? <span><FontAwesomeIcon icon={faHourglassHalf} style={{ color: 'orange' }} />&nbsp;&nbsp;&nbsp;Waiting for your response</span>
                        : <span><FontAwesomeIcon icon={faCircleCheck} style={{ color: 'green' }} />&nbsp;&nbsp;&nbsp;Responded</span>
                    }</p><br />
                    
                    <h4>Message from { contactMessage.firstName } { contactMessage.lastName }</h4>
                    {
                       contactMessage.organisation
                        ? <p>Organisation: <b>{contactMessage.organisation}</b></p>
                        : null
                    }
                    <p>
                        <b>Sender's email: </b>
                        <a href={"mailto: "+contactMessage.email}>
                            {contactMessage.email}
                        </a>
                    </p>
                    {
                        contactMessage.phonenumber
                        ? <p>Phone Number: <a href={"tel: "+contactMessage.phonenumber}>
                                                {contactMessage.phonenumber}
                                            </a>
                            </p>
                        : null
                    }
                    <div className="mt-4">
                        <p>
                            <b>Sent on:</b> {prettyPrintDateTime(contactMessage.sentOn)}
                        </p>
                    </div>
                    {
                        contactMessage.subject
                        ? <p>Subject: <b>{contactMessage.subject}</b></p>
                        : null
                    }
                    <p>
                        <b>Message: </b>{contactMessage.message}
                    </p>
                    {
                        contactMessage.notes
                        ? <p><br /><b>Your notes: </b>{contactMessage.notes}</p>
                        : null
                    }
                </div>
            </div>
            <div className="buttons-container">
                <Button variant="outline-primary"
                        className="mb-3"
                        onClick={() => alterMessageState(contactMessage._id, props.loadAll)}>
                    {
                        contactMessage.pending
                        ? <span>Mark as responded&nbsp;&nbsp;&nbsp;<FontAwesomeIcon icon={faCheck} /></span>
                        : <span>Restore as pending&nbsp;&nbsp;&nbsp;<FontAwesomeIcon icon={faArrowRotateLeft} /></span>
                    }
                </Button>

                <ContactMessageModal    id={contactMessage._id}
                                        loadAll={props.loadAll}
                                        contactMessage={contactMessage}
                                        action="edit" />

                <ContactMessageModal    id={contactMessage._id}
                                        loadAll={props.loadAll}
                                        contactMessage={contactMessage}
                                        action="remove" />
            </div>
        </div>);

};

export default memo(ContactMessageCard);
