import React, { memo } from 'react';
import { Row } from 'react-bootstrap';
import {    getRessouresUrl, 
            ellipsify, 
            prettyPrintDate,
            prettyPrintCategory,
            cleanDisplayFilename } from './../../../utils/config';
import PublicationModal from './PublicationModal';
import PdfViewerModal from './../../../components/pdf-viewer/PdfViewerModal';

import './../../../assets/css/style.css';

const PublicationCard = (props) => {
    const publication = props.data;
    const category = props.category;

    return (
        <div className="custom-card viewer card-publication card-style-steel-texture card-shadow">
            <div className="text-container">
                <div>
                    {/* Required fields */}
                    <h4>{ publication.title }</h4>
                    <hr className="slim-hr" />
                    <div className="mt-4">
                        <span>
                            <b>Published on: </b>
                            {prettyPrintDate(publication.publicationDate)}
                        </span>
                    </div>
                    {
                        category
                        ?   <div>
                                <br />
                                <span>
                                    <b>Publication category: </b>
                                    {prettyPrintCategory(category)}
                                </span>
                            </div>
                        : null
                    }
                    {/* Optional fields */}
                    {/* {
                        publication.description
                        ? <p>{ publication.description }</p>
                        : null
                    } */}
                    {
                        publication.publicationDocs.length !== 0
                        ?   <div key={publication._id+publication.title+"docs"}>
                                <br />
                                <span>
                                    <b>Documents & files</b>
                                </span>
                                {publication.publicationDocs.map((doc, index) => (
                                    <div key={cleanDisplayFilename(doc)+index}>
                                        <PdfViewerModal btnPrompt={cleanDisplayFilename(doc)}
                                                        modalPrompt={cleanDisplayFilename(doc)} 
                                                        ressource={getRessouresUrl()+doc}/>
                                        {index !== (publication.publicationDocs.length-1)
                                            ? <span><br /></span>
                                            : null
                                        }
                                    </div>
                                ))}
                            </div>
                        : null
                    }
                    {
                        publication.publicationImages.length !== 0
                        ?   <div key={publication._id+publication.title+"images"}>
                                <br />
                                <span>
                                    <b>Media</b>
                                </span>
                                <Row style={{ padding: '20px' }}>
                                {publication.publicationImages.map((img, index) => (
                                    <img    key={cleanDisplayFilename(img)+index}
                                            src={getRessouresUrl()+img} 
                                            alt={cleanDisplayFilename(img)} 
                                            className="image" />
                                ))}
                                </Row>
                            </div>
                        : null
                    }
                    {
                        publication.links.length !== 0
                        ?   <div>
                                <br />
                                <span>
                                    <b>Links & ressources</b>
                                </span>
                                {publication.links.map((data, index) => (
                                    <span key={data.link+index}>
                                        <a  target="_blank" 
                                            rel="noreferrer" 
                                            draggable={false}
                                            href={data.link} >
                                                {data.display
                                                    ? data.display
                                                    : ellipsify(data.link)
                                                }
                                        </a>    
                                        {index !== (publication.links.length-1)
                                            ? <span>, </span>
                                            : null
                                        }
                                    </span>))}
                            </div>
                        : null
                    }
                    <div>
                        <br />
                        <span>
                            <b>Description (Abstract)</b>
                        </span>
                    </div>
                    <span>{ publication.description }</span>
                </div>
            </div>
            <div className="buttons-container">
                <PublicationModal   id={publication._id}
                                    loadAll={props.loadAll}
                                    publication={publication}
                                    action="edit" />

                <PublicationModal   id={publication._id}
                                    loadAll={props.loadAll}
                                    publication={publication}
                                    action="remove" />
            </div>
        </div>);

};

export default memo(PublicationCard);
