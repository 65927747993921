import React, { Component, memo } from 'react';
import { Tabs, Tab } from 'react-bootstrap';
import ReportCard from './ReportCard';
import ReportModal from './ReportModal';
import { getApiUrl } from './../../../utils/config';
import { getToken } from './../../../session-utils/UserSession';
import CircularLoading from './../../../components/circular-loading/CircularLoading';


async function loadReports() {
  return fetch(getApiUrl() + "reports/", {
    method: 'GET',
    headers: {
      'Authorization': 'Bearer ' + getToken(),
      'Content-Type': 'application/json'
    },
    mode: 'cors',
    cache: 'default',
    credentials: 'omit'

  })
    .then(res => res.json())
    .then(data => { return data.reports })
    .catch(error => console.log(error))
}

function DashboardReports() {
  const handleLoadReports = async () => {
    return await loadReports();
  }

  return <ReportsComponent handleLoadReports={handleLoadReports} />
}

class ReportsComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      reports: {
        'issues': [],
        'resolvedIssues': [],
        'improvements': [],
        'implementedImprovements': []
      },
      handleLoadReports: props.handleLoadReports,
      isLoaded: false
    }
  }

  sortReportsByCreationDate = (reports) => {
    return reports.sort((a, b) => (new Date(a.creationDate) > new Date(b.creationDate)) ? -1 : ((new Date(a.creationDate) < new Date(b.creationDate)) ? 1 : 0))
  }

  sortReportsByType = (reports) => {
    // Sort all reports by creation date
    const sortedReports = this.sortReportsByCreationDate(reports);

    // Create seperate lists for each report type
    let issuesList = [];
    let resolvedIssuesList = [];
    let improvementsList = [];
    let implementedImprovementsList = [];

    // Populate seperate lists based off report type
    Array.from(sortedReports).map((report) => {
      if (report.category === "issues" && report.pending) 
        issuesList = [...issuesList, report];

      if (report.category === "issues" && !report.pending) 
        resolvedIssuesList = [...resolvedIssuesList, report];

      else if (report.category === "improvements" && report.pending) 
        improvementsList = [...improvementsList, report];

      else if (report.category === "improvements" && !report.pending) 
        implementedImprovementsList = [...implementedImprovementsList, report];
    
      return null;
    });

    return {
        'issues': issuesList,
        'resolvedIssues': resolvedIssuesList,
        'improvements': improvementsList,
        'implementedImprovements': implementedImprovementsList
      };
  }

  componentDidMount() {
    this.state.handleLoadReports().then(reports => {
      this.setState({ 
        reports: this.sortReportsByType(reports),
        isLoaded: true 
      })
    }).catch(error => console.log(error))
  }

  render() {
    const nbIssues = this.state.reports['issues'].length;
    const nbResolvedIssues = this.state.reports['resolvedIssues'].length;
    const nbImprovements = this.state.reports['improvements'].length;
    const nbImplementedImprovements = this.state.reports['implementedImprovements'].length;
    const nbAll = nbIssues + nbResolvedIssues + nbImprovements + nbImplementedImprovements;

    return (
      <>
        <h2>Manage reports</h2>

        {!this.state.isLoaded
          ? <div><CircularLoading /></div>
          : <>
              <ReportModal  action="add"
                            loadAll={() => this.state.handleLoadReports().then(reports => this.setState({ reports: this.sortReportsByType(reports) }))} />

              <Tabs
                defaultActiveKey="all"
                transition
                id="reports-tabs"
                className="mt-4 mb-4"
              >
                <Tab eventKey="all" title={ "All reports ("+ nbAll + ")"}>
                  {nbAll !== 0
                    ? 
                      <>
                        {this.sortReportsByCreationDate(
                          [...this.state.reports['issues'], 
                          ...this.state.reports['resolvedIssues'],
                          ...this.state.reports['improvements'],
                          ...this.state.reports['implementedImprovements']]).map(report => (
                          <ReportCard   key={report._id}
                                        data={report}
                                        loadAll={() => this.state.handleLoadReports().then(reports => this.setState({ reports: this.sortReportsByType(reports) }))} />
                        ))}
                      </>
                    : <span>No reports found</span>
                  }
                </Tab>
                <Tab eventKey="reportedIssues" title={ "Reported issues ("+ nbIssues + ")"}>
                  {nbIssues !== 0
                    ? 
                      <>
                        {this.state.reports['issues'].map(report => (
                          <ReportCard   key={report._id}
                                        data={report}
                                        loadAll={() => this.state.handleLoadReports().then(reports => this.setState({ reports: this.sortReportsByType(reports) }))} />
                        ))}
                      </>
                    : <span>No reported issues found</span>
                  }
                </Tab>
                <Tab eventKey="resolvedIssues" title={ "Resolved issues ("+ nbResolvedIssues + ")"}>
                  {nbResolvedIssues !== 0
                    ? 
                      <>
                        {this.state.reports['resolvedIssues'].map(report => (
                          <ReportCard   key={report._id}
                                        data={report}
                                        loadAll={() => this.state.handleLoadReports().then(reports => this.setState({ reports: this.sortReportsByType(reports) }))} />
                        ))}
                      </>
                    : <span>No resolved issues found</span>
                  }
                </Tab>
                <Tab eventKey="requestedImprovements" title={ "Requested improvements ("+ nbImprovements + ")"}>
                  {nbImprovements !== 0
                    ? 
                      <>
                        {this.state.reports['improvements'].map(report => (
                          <ReportCard   key={report._id}
                                        data={report}
                                        loadAll={() => this.state.handleLoadReports().then(reports => this.setState({ reports: this.sortReportsByType(reports) }))} />
                        ))}                          
                      </>
                    : <span>No requested improvements found</span>
                  }
                </Tab>
                <Tab eventKey="deliveredImprovements" title={ "Delivered improvements ("+ nbImplementedImprovements + ")"}>
                  {nbImplementedImprovements !== 0
                    ? 
                      <>
                        {this.state.reports['implementedImprovements'].map(report => (
                          <ReportCard  key={report._id}
                                        data={report}
                                        loadAll={() => this.state.handleLoadReports().then(reports => this.setState({ reports: this.sortReportsByType(reports) }))} />
                        ))}                          
                      </>
                    : <span>No delivered improvements found</span>
                  }
                </Tab>
              </Tabs>
            </>
        }
      </>
    );
  }

}

export default memo(DashboardReports);