import { Roles } from '.'
import {
	Dashboard,
	// DashboardAboutUs,
	// DashboardResearch,
	DashboardTeam,
	DashboardPublications,
	DashboardNews,
	DashboardEvents,
	DashboardMailingList,
	DashboardMessages,
	DashboardReportings
} from '../pages';

const PrivateRoutesConfig = [
	{
		component: Dashboard,
		path: '/',
		title: 'Dashboard',
		tabTitle: 'Dashboard',
		permission: [
			Roles.RESEARCHER
		]
	},
	// {
	// 	component: DashboardAboutUs,
	// 	path: 'm-about-us',
	// 	title: 'About us',
	//  tabTitle: 'Manage About Us',
	// 	permission: [
	// 		Roles.RESEARCHER
	// 	]
	// },
	{
		component: DashboardTeam,
		path: 'm-team',
		title: 'Team',
		tabTitle: 'Manage Team',
		permission: [
			Roles.RESEARCHER
		]
	},
	// {
	// 	component: DashboardResearch,
	// 	path: 'm-research',
	// 	title: 'Research',
	//  tabTitle: 'Manage Reserach',
	// 	permission: [
	// 		Roles.RESEARCHER
	// 	]
	// },
	{
		component: DashboardPublications,
		path: 'm-publications',
		title: 'Publications',
		tabTitle: 'Manage Publications',
		permission: [
			Roles.RESEARCHER
		]
	},
	{
		component: DashboardNews,
		path: 'm-news',
		title: 'News',
		tabTitle: 'Manage News',
		permission: [
			Roles.RESEARCHER
		]
	},
	{
		component: DashboardEvents,
		path: 'm-events',
		title: 'Events',
		tabTitle: 'Manage Events',
		permission: [
			Roles.RESEARCHER
		]
	},
	{
		component: DashboardMailingList,
		path: 'm-mailing-list',
		title: 'Mailing List',
		tabTitle: 'Manage Mailing List',
		permission: [
			Roles.RESEARCHER
		]
	},
	{
		component: DashboardMessages,
		path: 'm-messages',
		title: 'Messages',
		tabTitle: 'Manage Messages',
		permission: [
			Roles.RESEARCHER
		]
	},
	{
		component: DashboardReportings,
		path: 'm-reporting',
		title: 'Reports',
		tabTitle: 'Manage Reports',
		permission: [
			Roles.RESEARCHER
		]
	},
];

export default PrivateRoutesConfig;