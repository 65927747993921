import React, { memo} from "react";
import { NavLink } from 'react-router-dom'; 
import { faXmark } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { isLoggedIn } from '../../../session-utils';
import Logout from '../../../session-utils/logout';

import './mobile.css';

function MobileNavbar({ routes, isOpen, setIsOpen, currentLocation, dimClassName }) {  
  return (
    <div className="mobile" id={dimClassName+"-mobile-nav"}>
      <div className="close-icon" onClick={() => setIsOpen(!isOpen)}>
        <FontAwesomeIcon icon={faXmark} />
      </div>

      {routes.map(({ path, title }) => (
        <div key={path} className="mobile-option">
          {currentLocation === ('/'+path)
          ? <NavLink className="navbar-link active" to={`${path}`} onClick={() => setIsOpen(!isOpen)}>{title}</NavLink>
          : <NavLink className="navbar-link" to={`${path}`} onClick={() => setIsOpen(!isOpen)}>{title}</NavLink>
        }
        </div>
      ))}

      {isLoggedIn()
        ? <div className="mobile-option">
            <Logout collapsed={true} />
          </div>
        : null
      }
    </div>
  );
}

export default memo(MobileNavbar);