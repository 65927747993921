import React, { memo } from 'react';
import { getRessouresUrl, ellipsify, cleanDisplayFilename } from '../../utils/config';
import PdfViewerModal from '../../components/pdf-viewer/PdfViewerModal';

import defaultProfilePic from '../../assets/images/default_profilepic.png';

import '@react-pdf-viewer/core/lib/styles/index.css';
import './../../assets/css/style.css';

const TeamCard = (props) => {
    const member = props.data;

    return (
        <div className="custom-card viewer card-public card-shadow" key={member.name+member.title}>
            <div className="image-container">
                { member.profilepic 
                    ? <img  className="image circular"
                            draggable={false}
                            src={getRessouresUrl() + member.profilepic}
                            alt={member.name + " profile picture"} />

                    : <img  className="image circular"
                            draggable={false}
                            src={defaultProfilePic}
                            alt="User default profile" /> 
                }
            </div>
            <div className="text-container">
                <div>
                    {/* Required fields */}
                    <h3>{ member.name } <span style={{ fontFamily: 'BentonSans-Light', fontSize: '2.5vh', fontWeight: "normal" }}>({ member.title })</span></h3>
                    <div>
                        <h5>{ member.role }</h5>
                    </div>
                    {
                        member.mail
                            ?   <div>
                                    <p>
                                        <a  href={ "mailto:"+member.mail }
                                            draggable={false}>
                                                { member.mail }
                                        </a>
                                    </p>
                                </div>
                            : null
                    }
                    {
                        member.bio
                            ? <p>{ member.bio }</p>
                            : null
                    }
                    {
                        member.cvs.length !== 0
                        ?   <div key={member._id+member.name}>
                                <br />
                                <p>Curriculum vitæ (CV)</p>
                                {member.cvs.map((cv, index) => (
                                    <div key={cleanDisplayFilename(cv)+index}>
                                        <PdfViewerModal btnPrompt={cleanDisplayFilename(cv)}
                                                        modalPrompt={member.name+"'s CV"} 
                                                        ressource={getRessouresUrl()+cv}/>
                                        {index !== (member.cvs.length-1)
                                            ? <span><br /></span>
                                            : null
                                        }
                                    </div>
                                ))}
                            </div>
                        : null
                    }
                    {
                        member.links.length !== 0
                        ?   <div>
                                <br />
                                <p>Links and ressources</p>
                                {member.links.map((data, index) => (
                                    <span key={data.link+index}> 
                                        <a  target="_blank" 
                                            rel="noreferrer" 
                                            draggable={false}
                                            href={data.link} >
                                                {data.display
                                                    ? data.display
                                                    : ellipsify(data.link)
                                                }
                                        </a>    
                                        {index !== (member.links.length-1)
                                            ? <span>, </span>
                                            : null
                                        }
                                    </span>))}
                            </div>
                        : null
                    }
                </div>
            </div>
            {/* <div className="buttons-container"></div> */}
        </div>);

};

export default memo(TeamCard);
