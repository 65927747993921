import React, { memo } from "react";
import { Col, Row } from "react-bootstrap";
import {  faMapMarkerAlt,
          faPhoneAlt,
          faFax,
          faEnvelope } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import img_epa from "../../assets/images/footer/epa.png";
import img_idrc from "../../assets/images/footer/idrc.png";
import img_ipcc from "../../assets/images/footer/ipcc.png";
import img_unfccc from "../../assets/images/footer/unfccc.png";

import "./footer.css";

function Footer() {
  let join_network_url = "https://arca-alexandria.maps.arcgis.com/apps/GeoForm/index.html?appid=0ecceb24dcb146c1980755a7c65ca86f";
  let network_map = "http://arca-alexandria.maps.arcgis.com/apps/SummaryViewer/index.html?appid=e27f7c001fe64fdabd9795c0b95691ec";
  let search_for_colleagues = "http://arca-alexandria.maps.arcgis.com/apps/InteractiveFilter/index.html?appid=6f6d37a2aef84436898f8bc6d3f1d08e";

  return (
    <>
    <div className="footer">
      <div className="footer-container">
        <h3 className="footer-title">Partners</h3>
        <Row className="footer-row">
          <Col>
            <a href="https://idrc.ca" target="_blank" rel="noreferrer" title="IDRC — International Development Research Centre">
              <img className="footer-img" src={img_idrc} alt="IDRC" />
            </a>
            <a href="https://epa.gov" target="_blank" rel="noreferrer" title="EPA — Enivornmental Protection Agency">
              <img className="footer-img" src={img_epa} alt="EPA" />
            </a>
          </Col>
        </Row>
        <Row className="footer-row">
          <Col>
            <a href="https://ipcc.ch" target="_blank" rel="noreferrer" title="IPCC — The Intergovernmental Panel on Climate Change">
              <img className="footer-img" src={img_ipcc} alt="IPCC" />
            </a>
            <a href="https://unfccc.int" target="_blank" rel="noreferrer" title="UNFCCC — United Nations Framework Convention on Climate Change">
              <img className="footer-img" src={img_unfccc} alt="" />
            </a>
          </Col>
        </Row>
      </div>

      <div className="footer-container">
        <h3 className="footer-title">Network</h3>
          <a href={join_network_url} target="_blank" rel="noreferrer">Join ARCA's network</a>
          <a href={network_map} target="_blank" rel="noreferrer">ARCA's network map</a>
          <a href={search_for_colleagues} target="_blank" rel="noreferrer">Search for colleagues</a>
      </div>

      <div className="footer-container">
        <h3 className="footer-title">Contact Us</h3>
        <br />
        <table className="footer-table">
          <tbody>
            <tr>
              <td><FontAwesomeIcon icon={faMapMarkerAlt} /></td>
              <td><p>163 Horreya Avenue, El-Shatby, Alexandria, Egypt</p></td>
            </tr>
            <tr>
              <td><FontAwesomeIcon icon={faPhoneAlt} /></td>
              <td><a href="tel: +20 34249290">+20 34249290</a></td>
            </tr>
            <tr>
              <td><FontAwesomeIcon icon={faFax} /></td>
              <td><a href="tel: +20 34249290">+20 34249290</a></td>
            </tr>
            <tr>
              <td><FontAwesomeIcon icon={faEnvelope} /></td>
              <td><a href="mailto:support@arca-eg.net" target="_blank" rel="noreferrer">support@arca-eg.net</a></td>
            </tr>              
          </tbody>
        </table>
      </div>
    </div >
    <span className="footer-signature">© {new Date().getFullYear()} ARCA – All rights reserved</span>
    </>
  );
}

export default memo(Footer);