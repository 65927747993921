export const getApiUrl = () => {
	if (process.env.REACT_APP_IN_PRODUCTION === "TRUE")
		return process.env.REACT_APP_PROD_API_KEY + "api/";
	else
		return process.env.REACT_APP_LOCAL_API_KEY + "api/";
}

export const getRessouresUrl = () => {
	if (process.env.REACT_APP_IN_PRODUCTION === "TRUE") 
		return process.env.REACT_APP_PROD_API_KEY;
	else
		return process.env.REACT_APP_LOCAL_API_KEY;
}

export const ellipsify = (str) => {
	str = str.toString();

	if (str.includes('https://www.')) {
		str = str.replace('https://www.', '');
	}
	
	if (str.includes('http://www.')) {
		str = str.replace('http://www.', '');
	}

	if (str.includes('www.')) {
		str = str.replace('www.', '');
	}

	if (str.length > 15) {
		return (str.substring(0, 15) + "...");
	} else {
		return str;
	}
}

export const cleanDisplayFilename = (completeFilename) => {
	if (completeFilename.includes('/')) {
		let extension = completeFilename.substring(completeFilename.length, completeFilename.lastIndexOf('.'));
		return completeFilename.split('/')[2].replace(
					completeFilename.substring(
						completeFilename.lastIndexOf('_'), 
						completeFilename.length),
					extension)
	}
	else
		return completeFilename;
}

export const isValidHttpUrl = (urlString) => {
	try {
		const newUrl = new URL(urlString);

		var urlPattern = new RegExp('((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|'+ // validate domain name
        							'((\\d{1,3}\\.){3}\\d{1,3}))'+ // validate OR ip (v4) address
        							'(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*'+ // validate port and path
        							'(\\?[;&a-z\\d%_.~+=-]*)?'+ // validate query string
        							'(\\#[-a-z\\d_]*)?$','i' // validate fragment locator
								);

		return ((newUrl.protocol === 'http:' || newUrl.protocol === 'https:') && !!urlPattern.test(urlString));
	} catch (err) {
		return false;
	}
}

export const prettyPrintDate = (date) => {
	const monthsArr = ['January', 'February', 'March', 'April', 'May', 'June', 'July',
		'August', 'September', 'October', 'November', 'December'];

	const dateObj = new Date(date);
	const day  = dateObj.getDate();
	const month  = monthsArr[dateObj.getMonth()];
	const year = dateObj.getFullYear();

    return  `${day} ${month} ${year}`;
}

export const prettyPrintDateTime = (date) => {
	const monthsArr = ['January', 'February', 'March', 'April', 'May', 'June', 'July',
		'August', 'September', 'October', 'November', 'December'];

	const dateObj = new Date(date);
	const day  = dateObj.getDate();
	const month  = monthsArr[dateObj.getMonth()];
	const year = dateObj.getFullYear();
	const time = dateObj.toLocaleTimeString([], {hour: '2-digit', minute: '2-digit'});

    return  `${day} ${month} ${year} at ${time}`;
}

export const prettyPrintDuration = (date1, date2) => {
	const monthsArr = ['January', 'February', 'March', 'April', 'May', 'June', 'July',
		'August', 'September', 'October', 'November', 'December'];
	
	const startDate = new Date(date1);
	const endDate = new Date(date2);

	let startYear;
	let startMonth;
	let endMonth;

	if (startDate.getFullYear() === endDate.getFullYear()) {
		startYear = startDate.getFullYear();

		if (monthsArr[startDate.getMonth()] === monthsArr[endDate.getMonth()]) {
			startMonth = endMonth = monthsArr[startDate.getMonth()];

			if (startDate.getDate() === endDate.getDate()) {
				return startDate.getDate() + " " + startMonth + " " + startYear;
			} 
			
			return startDate.getDate() + " - " + endDate.getDate() + " " + endMonth + " " + startYear;

		} else {
			return startDate.getDate() + " " + monthsArr[startDate.getMonth()] + " - " + endDate.getDate() + " " + monthsArr[endDate.getMonth()] + " " + startYear;
		}
	} else {
		return prettyPrintDate(startDate) + " - " + prettyPrintDate(endDate);
	}
}

export const prettyPrintCategory = (category) => {
	if (category === "researchpapers")
		return "Research paper";
	if (category === "workingpapers")
		return "Working paper";
	if (category === "briefs")
		return "Brief";
	if (category === "technicalreports")
		return "Technical report";

	if (category === "issues")
		return "Issue";
	if (category === "improvements")
		return "Improvement"
	
	return "";
}

/**
 * Format bytes as human-readable text.
 * 
 * @param bytes Number of bytes.
 * @param si True to use metric (SI) units, aka powers of 1000. False to use 
 *           binary (IEC), aka powers of 1024.
 * @param dp Number of decimal places to display.
 * 
 * @return Formatted string.
 */
export const humanReadableFileSize = (bytes, si=true, dp=1) => {
	// Absolute value of bytes, make function accept negative values
	bytes = Math.abs(bytes)
	
	const thresh = si ? 1000 : 1024;

	if (Math.abs(bytes) < thresh) {
		return bytes + ' B';
	}

	const units = si 
		? ['kB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'] 
		: ['KiB', 'MiB', 'GiB', 'TiB', 'PiB', 'EiB', 'ZiB', 'YiB'];
	let u = -1;
	const r = 10**dp;

	do {
		bytes /= thresh;
		++u;
	} while (Math.round(Math.abs(bytes) * r) / r >= thresh && u < units.length - 1);


	return bytes.toFixed(dp) + ' ' + units[u];
}
