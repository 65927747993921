import React, { Component, memo } from 'react';
import { Tabs, Tab } from 'react-bootstrap';
import PublicationCard from './PublicationCard';
import PublicationModal from './PublicationModal';
import { getApiUrl } from './../../../utils/config';
import CircularLoading from './../../../components/circular-loading/CircularLoading';


async function loadPublications() {
  return fetch(getApiUrl() + "publications/", {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json'
    },
    mode: 'cors',
    cache: 'default',
    credentials: 'omit'

  })
    .then(res => res.json())
    .then(data => { return data.publications })
    .catch(error => console.log(error))
}

function DashboardPublications() {
  const handleLoadPublications = async () => {
    return await loadPublications();
  }

  return <PublicationsComponent handleLoadPublications={handleLoadPublications} />
}

class PublicationsComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      publications: {
        'researchPapers': [],
        'workingPapers': [],
        'briefs': [],
        'technicalReports': [],
      },
      handleLoadPublications: props.handleLoadPublications,
      isLoaded: false
    }
  }

  sortPublicationsByPublicationDate = (publications) => {
    return publications.sort((a, b) => (new Date(a.publicationDate) > new Date(b.publicationDate)) ? -1 : ((new Date(a.publicationDate) < new Date(b.publicationDate)) ? 1 : 0))
  }

  sortPublicationsByType = (publications) => {
    // Sort all publications by publication date
    const sortedPublications = this.sortPublicationsByPublicationDate(publications);

    // Create seperate lists for each publication type
    let researchPapersList = [];
    let workingPapersList = [];
    let briefsList = [];
    let technicalReportsList = [];

    // Populate seperate lists based off publication type
    Array.from(sortedPublications).map((publication) => {
      if (publication.category === "researchpapers") 
        researchPapersList = [...researchPapersList, publication];

      else if (publication.category === "workingpapers")
        workingPapersList = [...workingPapersList, publication];

      else if (publication.category === "briefs") 
        briefsList = [...briefsList, publication];
      
      else if (publication.category === "technicalreports")
        technicalReportsList = [...technicalReportsList, publication];

      return null;
    });

    return {
        'researchPapers': researchPapersList,
        'workingPapers': workingPapersList,
        'briefs': briefsList,
        'technicalReports': technicalReportsList,
      };
  }

  componentDidMount() {
    this.state.handleLoadPublications().then(publications => {
      this.setState({ 
        publications: this.sortPublicationsByType(publications),
        isLoaded: true 
      })
    }).catch(error => console.log(error))
  }

  render() {
    const nbResearchPapers = this.state.publications['researchPapers'].length;
    const nbWorkingPapers = this.state.publications['workingPapers'].length;
    const nbBriefs = this.state.publications['briefs'].length;
    const nbTechnicalReports = this.state.publications['technicalReports'].length;
    const nbAll = nbResearchPapers + nbWorkingPapers + nbBriefs + nbTechnicalReports;

    return (
      <>
        <h2>Manage publications</h2>

        {!this.state.isLoaded
          ? <div><CircularLoading /></div>
          : <>
              <PublicationModal action="add"
                                loadAll={() => this.state.handleLoadPublications().then(publications => this.setState({ publications: this.sortPublicationsByType(publications) }))} />

              <Tabs
                defaultActiveKey="all"
                transition
                id="publication-tabs"
                className="mt-4 mb-4"
              >
                <Tab eventKey="all" title={ "All publications ("+ nbAll + ")"}>
                  {nbAll !== 0
                    ? 
                      <>
                        {this.sortPublicationsByPublicationDate(
                          [...this.state.publications['researchPapers'], 
                          ...this.state.publications['workingPapers'],
                          ...this.state.publications['briefs'], 
                          ...this.state.publications['technicalReports']]).map(publication => (
                          <PublicationCard  key={publication._id}
                                            category={publication.category}
                                            data={publication}
                                            loadAll={() => this.state.handleLoadPublications().then(publications => this.setState({ publications: this.sortPublicationsByType(publications) }))} />
                        ))}
                      </>
                    : <span>No publications found</span>
                  }
                </Tab>
                <Tab eventKey="researchPapers" title={ "Research Papers ("+ nbResearchPapers + ")"}>
                  {nbResearchPapers !== 0
                    ? 
                      <>
                        {this.state.publications['researchPapers'].map(publication => (
                          <PublicationCard  key={publication._id}
                                            data={publication}
                                            loadAll={() => this.state.handleLoadPublications().then(publications => this.setState({ publications: this.sortPublicationsByType(publications) }))} />
                        ))}
                      </>
                    : <span>No research papers found</span>
                  }
                </Tab>
                <Tab eventKey="workingPapers" title={ "Working Papers ("+ nbWorkingPapers + ")"}>
                  {nbWorkingPapers !== 0
                    ? 
                      <>
                        {this.state.publications['workingPapers'].map(publication => (
                          <PublicationCard  key={publication._id}
                                            data={publication}
                                            loadAll={() => this.state.handleLoadPublications().then(publications => this.setState({ publications: this.sortPublicationsByType(publications) }))} />
                        ))}
                      </>
                    : <span>No working papers found</span>
                  }
                </Tab>
                <Tab eventKey="briefs" title={ "Briefs ("+ nbBriefs + ")"}>
                  {nbBriefs !== 0
                    ? 
                      <>
                        {this.state.publications['briefs'].map(publication => (
                          <PublicationCard  key={publication._id}
                                            data={publication}
                                            loadAll={() => this.state.handleLoadPublications().then(publications => this.setState({ publications: this.sortPublicationsByType(publications) }))} />
                        ))}                          
                      </>
                    : <span>No briefs found</span>
                  }
                </Tab>
                <Tab eventKey="technicalReports" title={ "Technical Reports ("+ nbTechnicalReports + ")"}>
                  {nbTechnicalReports !== 0
                    ? 
                      <>
                        {this.state.publications['technicalReports'].map(publication => (
                          <PublicationCard  key={publication._id}
                                            data={publication}
                                            loadAll={() => this.state.handleLoadPublications().then(publications => this.setState({ publications: this.sortPublicationsByType(publications) }))} />
                        ))}                   
                      </>
                    : <span>No technical reports found</span>
                  }
                </Tab>
              </Tabs>
            </>
        }
      </>
    );
  }

}

export default memo(DashboardPublications);