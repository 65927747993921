import React, { useEffect, useState } from "react";
import PropTypes from 'prop-types';
import AppBar from '@mui/material/AppBar';
// import Toolbar from '@material-ui/core/Toolbar';
import CssBaseline from '@mui/material/CssBaseline';
import useScrollTrigger from '@mui/material/useScrollTrigger';
import Slide from '@mui/material/Slide';
import { faBars } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { NavLink, useLocation } from 'react-router-dom';
import { isLoggedIn } from './../../session-utils';

import MobileNavbar from './mobile';
import ExtendedNavbar from './web';

import arca_icon_white from './../../assets/images/logo/logos_minimal_white.png';  

import './../../assets/css/style.css';
import "./header.css";


function HideOnScroll(props) {
  const { children, window } = props;
  // Note that you normally won't need to set the window ref as useScrollTrigger
  // will default to window.
  // This is only being set here because the demo is in an iframe.
  const trigger = useScrollTrigger({ target: window ? window() : undefined });

  return (
    <Slide appear={false} direction="down" in={!trigger}>
      {children}
    </Slide>
  );
}

HideOnScroll.propTypes = {
  children: PropTypes.element.isRequired,
  window: PropTypes.func,
};

function Header(props) {
  const [isOpen, setIsOpen] = useState(false);
  const [homeRedirection, setHomeRedirection] = useState("/");
  const [dimClassName, setDimClassName] = useState("navbar");
  const currentLocation = useLocation().pathname;

  useEffect(() => {
    // Disable scroll when mobile menu is opened
    if (isOpen) {
      document.body.classList.add("overflow-y-hidden")
    } else {
      document.body.classList.remove("overflow-y-hidden")
    } 
  }, [isOpen]);
  
  useEffect(() => {
    setHomeRedirection('/');

    if (isLoggedIn()) {
      setHomeRedirection('/dashboard/');
      setDimClassName("dim-styling");
    }

  }, [homeRedirection, dimClassName]);

  return (
    <>
      <CssBaseline />
      <HideOnScroll {...props}>
        <AppBar className="navbar header">
          <div className="menu">
            <div className="web-menu" id={dimClassName}>
              <ExtendedNavbar homeRedirection={homeRedirection} 
                              routes={props.routes} 
                              prefix={props.prefix}
                              currentLocation={currentLocation}
                              dimClassName={dimClassName} />
            </div>
            <div className="mobile-menu">
              <NavLink className="navbar-link logo" to={homeRedirection}>
                <img src={arca_icon_white} alt="ARCA" className="image" title="Homepage"/>
              </NavLink>
              <div className="menu-icon" onClick={()=>setIsOpen(!isOpen)}>
                <FontAwesomeIcon icon={faBars} />
              </div>
              {isOpen && <MobileNavbar  routes={props.routes} 
                                        // prefix={props.prefix}
                                        isOpen={isOpen}
                                        setIsOpen={setIsOpen}
                                        currentLocation={currentLocation}
                                        dimClassName={dimClassName} />
              }
            </div>
          </div>
        </AppBar>
      </HideOnScroll>
        {/* <Toolbar /> */}
    </>
  );
}

export default Header;