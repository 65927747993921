import React, { memo } from "react";
import {  faPuzzlePiece,
          faSeedling } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import './../../assets/css/style.css';
import './../home/home.css';
import './../about-us/about-us.css';
import './research.css';

function Research() {
  return (
  <div>
    <h2>Research</h2>

    {/* PRESENTATION CARD */}
    <div className="container responsive-container-top">
      <div className="presentation-card about-us-description card-style-steel-texture-blue card-shadow">
        <p>Egypt is one of the most vulnerable countries in the region, already experiencing the severe impacts of climate change. ARCA serves as a multidisciplinary research hub, facilitating open, critical inquiry into climate change and development issues. Through its research, ARCA aims to enhance knowledge, drive innovative solutions, and develop strategies that promote long-term sustainability in the face of climate challenges.</p>
      </div>
    </div>

    <div className="container responsive-container">
      <div className="presentation-card about-us-description card-shadow card-style-steel-texture-blue">
        <h4>
          <span className="important-title"><FontAwesomeIcon icon={faPuzzlePiece} />&nbsp;&nbsp;</span>
          <b>Our Research Objectives</b>
        </h4>
        <hr />
        <ul className="bullet-li">
          <li>
            <p>
              <b>Develop an integrated approach to climate change research:</b> by synthesizing diverse disciplines and methodologies to address complex climate challenges.
            </p>
          </li>
        </ul>
        
        <hr className="slim-hr" />
        
        <ul className="bullet-li">
          <li>
            <p>
              <b>Support informed decision-making and policy development:</b> through evidence-based research that guides effective climate action and adaptation strategies.
            </p>
          </li>
        </ul>  
          <hr className="slim-hr" />
        
        <ul className="bullet-li">
          <li>
            <p>
              <b>Enhance understanding of climate change economics:</b> by analyzing the broader economic impacts of climate change and developing targeted solutions for the economics of adaptation. 
            </p>
          </li>
        </ul>
      </div>
    </div>

    <div className="homepage-container">
      {/* PRESENTATION CARD */}
      <h3 className="container container-sub-title">
        ARCA’s research fields include
      </h3>
      <br />
      
      <div className="services-presentation-cards">             
          {/* YEARS OF EXPERIENCE CARD */}
          <div className="figures-card card-style-steel-texture-orange card-shadow" style={{ justifyContent: "flex-start" }}>
            <div><h2>1</h2></div><br />
            <h5><b><span className="important-text">Vulnerability assessment</span> of climate change impacts in the Nile Delta region</b></h5>
          </div>

          {/* PUBLICATIONS CARD */}
          <div className="figures-card card-style-steel-texture-orange card-shadow" style={{ justifyContent: "flex-start" }}>
            <div><h2>2</h2></div><br />
            <h5><b><span className="important-text">Socioeconomic profiling</span> of the Nile Delta coastal zone</b></h5>
          </div>
          
          {/* SEMINAR CARD */}
          <div className="figures-card card-style-steel-texture-orange card-shadow" style={{ justifyContent: "flex-start" }}>
            <div><h2>3</h2></div><br />
            <h5><b><span className="important-text">Economic valuation</span> of climate change impacts and sea-level rise</b></h5>
          </div>
          
          {/* APPRENTICES CARD */}
          <div className="figures-card card-style-steel-texture-orange card-shadow" style={{ justifyContent: "flex-start" }}>
            <div><h2>4</h2></div><br />
            <h5><b><span className="important-text">Adaptation options</span> to mitigate climate change effects</b></h5>
          </div>
      </div>
    </div>

    <div className="container responsive-container">
      <div className="presentation-card card-style-steel-texture-blue card-shadow">
        <h4>
          <span className="important-title"><FontAwesomeIcon icon={faSeedling} />&nbsp;&nbsp;</span>
          <b>Available and awarded research grants</b>
        </h4>
        <hr />
        <p style={{ padding: "1rem 0 1.5rem 0" }}>
          The following research grants were awarded within the past five years:
        </p>
        <ul id="small-li" className="bullet-li">
          <li>
            <p>
              <b>Vulnerability and adaptation to sea level rise</b> in the Egyptian coastal lakes. 
            </p>
          </li>
        </ul>
        
        <hr className="slim-hr" />

        <ul id="small-li" className="bullet-li">
          <li>
            <p>
              <b>Patterns of biodiversity and climate change</b> in the Mediterranean transitional zone. 
            </p>
          </li>
        </ul>

        <hr className="slim-hr" />

        <ul id="small-li" className="bullet-li">
          <li>
            <p>
              <b>Impact of sea level rise on buildings and infrastructure</b>, with recommendations for preventive measures.
            </p>
          </li>
        </ul>

        <hr className="slim-hr" />

        <ul id="small-li" className="bullet-li">
          <li>
            <p>
              <b>Vulnerability of animal production</b> to high ambient temperatures and potential adaptation strategies in Egypt.
            </p>
          </li>
        </ul>

        <hr className="slim-hr" />

        <ul id="small-li" className="bullet-li">
          <li>
            <p>
              <b>Downscaling sea level rise projections</b> for the Mediterranean Sea under various future climate scenarios (2065-2100).
            </p>
          </li>
        </ul>

        <hr className="slim-hr" />

        <ul id="small-li" className="bullet-li">
          <li>
            <p>
              <b>Sustainable potato production strategies</b> under changing climate conditions.
            </p>
          </li>
        </ul>
      </div>
    </div>

      {/* Research

      Egypt is amongst the developing and vulnerable countries that is already facing the impacts of climate change in the region.
      ARCA brings the many disciplines for open critical inquiry of climate change and development research issues. 
      As a research hub, ARCA aims to improve knowledge and foster strategies that advance the sustainability.

      ARCA research objectives:
      - To create an integrated approach to climate change research.
      - To support decision/policy making process.
      - To provide better understanding and devise measures to deal with climate change economics in general and economics of adaptation in particular.
  
      ARCA research fields encompasses but are not limited to:
      - Vulnerability assessment of climate change in the Nile Delta region.
      - Socioeconomic profiling of the Nile Delta coastal zone.
      - Economic valuation of climate change impacts and Sea level rise.
      - Adaptation options to climate change impacts.

      Available and awarded research grants:
      The following research grants were awarded within the past five years:
      - Assessment of vulnerability and adaptation to sea level rise for the Egyptian coastal lakes
      - Patterns of diversity and climate change in a Mediterranean transitional Zone
      - Investigating the effect of sea level rise on buildings and infrastructure and the suggested preventive measures
      - Vulnerability of animal production to high ambient temperature and possible adaptation options under Egyptian conditions
      - Downscaling sea level rise in the Mediterranean Sea under different future climate change scenarios (2065-2100)
      - Towards Sustainable Potato Production under Climate Change Conditions.
       */}


  </div>
  );
}

export default memo(Research);