import React, { memo } from 'react';
import {    getRessouresUrl, 
            ellipsify, 
            cleanDisplayFilename } from './../../../utils/config';
import TeamModal from './TeamModal';
import PdfViewerModal from './../../../components/pdf-viewer/PdfViewerModal';
import ImageEditorModal from './../../../components/img-editor/ImageEditorModal';

import './../../../assets/css/style.css';

const TeamCard = (props) => {
    const member = props.data;
    const nbMembers = props.nbMembers;

    return (
        <div className="custom-card card-style-steel-texture card-shadow">
            <div className="image-container">
                <ImageEditorModal   memberId={member._id} 
                                    memberFullname={member.name}
                                    memberProfilePic={member.profilepic}
                                    loadAll={props.loadAll} />
            </div>
            <div className="text-container">
                <div>
                    {/* Required fields */}
                    <h3>{ member.name } <span style={{ fontFamily: 'BentonSans-Light', fontSize: '2.5vh' }}>({ member.title })</span></h3>
                    <div>
                        <h5>{ member.role }</h5>
                    </div>
                    {
                        member.mail
                            ?   <p>
                                    <a  href={ "mailto:"+member.mail }
                                        draggable={false}>
                                            { member.mail }
                                    </a>
                                </p>
                            : null
                    }
                    {
                        member.bio
                        ? <p>{ member.bio }</p>
                        : null
                    }
                    {
                        member.cvs.length !== 0
                        ?   <div key={member._id+member.name}>
                                <br />
                                <p>Curriculum vitæ (CV)</p>
                                {member.cvs.map((cv, index) => (
                                    <div key={cleanDisplayFilename(cv)+index}>
                                        <PdfViewerModal btnPrompt={cleanDisplayFilename(cv)}
                                                        modalPrompt={member.name+"'s CV"} 
                                                        ressource={getRessouresUrl()+cv}/>
                                        {index !== (member.cvs.length-1)
                                            ? <span><br /></span>
                                            : null
                                        }
                                    </div>
                                ))}
                            </div>
                        : null
                    }
                    {
                        member.links.length !== 0
                        ?   <div>
                                <br />
                                <p>Links and ressources</p>
                                {member.links.map((data, index) => (
                                    <span key={data.link+index}>
                                        <a  target="_blank" 
                                            rel="noreferrer" 
                                            draggable={false}
                                            href={data.link} >
                                                {data.display
                                                    ? data.display
                                                    : ellipsify(data.link)
                                                }
                                        </a>    
                                        {index !== (member.links.length-1)
                                            ? <span>, </span>
                                            : null
                                        }
                                    </span>))}
                            </div>
                        : null
                    }
                </div>
            </div>
            <div className="buttons-container">
                <TeamModal  id={member._id}
                            loadAll={props.loadAll}
                            member={member}
                            nbMembers={nbMembers} 
                            action="edit" />

                <TeamModal  id={member._id}
                            loadAll={props.loadAll}
                            member={member}
                            nbMembers={nbMembers}
                            action="remove" />
            </div>
        </div>);

};

export default memo(TeamCard);
