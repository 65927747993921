import React, { Component, memo } from "react";
import { Button } from 'react-bootstrap';
import { faCopy, faEnvelopesBulk } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Snackbar from '@mui/material/Snackbar';
import Slide from '@mui/material/Slide';
import Alert from '@mui/material/Alert';
import MailingListCard from './MailingListCard';
import MailingListModal from './MailingListModal';
import { getApiUrl } from '../../../utils/config';
import { getToken } from '../../../session-utils/UserSession';
import CircularLoading from '../../../components/circular-loading/CircularLoading';


async function loadMailingList() {
  return fetch(getApiUrl() + "mailinglist/", {
    method: 'GET',
    headers: {
      'Authorization': 'Bearer ' + getToken(),
      'Content-Type': 'application/json'
    },
    mode: 'cors',
    cache: 'default',
    credentials: 'omit'

  })
    .then(res => res.json())
    .then(data => { return data.mailinglist })
    .catch(error => console.log(error))
}

function SlideTransition(props) {
  return <Slide {...props} direction="left" />;
}

function mailingListToString(mailinglist) {
  let strMailingList = "";

  mailinglist.map((mail) => {
    strMailingList += mail.email+";";

    return null;
  })

  return strMailingList;
}

function sendNewsLetter(strMailinglist) {
  // Copy the mailing list to clipboard
  navigator.clipboard.writeText(strMailinglist);
}

function DashboardMailingList() {
  const handleLoadMailingList = async () => {
    return await loadMailingList();
  }

  return <MailingListComponent handleLoadMailingList={handleLoadMailingList} />
}

class MailingListComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loadMailingList: [],
      handleLoadMailingList: props.handleLoadMailingList,
      isLoaded: false,
      showSnackbar: false,
    }
  }

  sortEmailsAlphabetically = (mailinglist) => {
    return mailinglist.sort((a, b) => (a.email < b.email) ? -1 : (a.email > b.email) ? 1 : 0)
  }

  componentDidMount() {
    this.state.handleLoadMailingList().then(mailinglist => {
      this.setState({ 
        mailinglist: this.sortEmailsAlphabetically(mailinglist),
        strMailingList: mailingListToString(mailinglist),
        isLoaded: true,
      })
    }).catch(error => console.log(error))
  }

  handleShowSnackbar = () => {
    this.setState({ showSnackbar: true });
  };

  handleHideSnackbar = () => {
    this.setState({ showSnackbar: false });
  };

  render() {
    return (
      <>
        <h2>Manage mailing list</h2>

        {!this.state.isLoaded
          ? <div><CircularLoading /></div>
          :
          <>
            {!this.state.mailinglist
              ? <div>No subscribers found in mailing list</div>
              : <>

                  <h4 className="mt-4 mb-3"><b>Newsletter total subscribers count: </b>
                    <span className="important-text">{this.state.mailinglist.length}</span>
                  </h4>

                  <Button style={{ width: '350px' }}
                          variant="outline-warning"
                          onClick={() => {
                                      sendNewsLetter(this.state.strMailingList);
                                      this.handleShowSnackbar();
                                  }} >
                    Copy mailing list to clipboard&nbsp;&nbsp;&nbsp;<FontAwesomeIcon icon={faCopy} />
                  </Button>
                  <br />
                  <a href={"mailto:?cc=" + this.state.strMailingList + "&subject=ARCA's%20Newsletter"} >
                    <Button style={{ width: '350px' }}
                            variant="outline-warning"
                            onClick={() => {}} >
                      Send newsletter to all subscribers&nbsp;&nbsp;&nbsp;<FontAwesomeIcon icon={faEnvelopesBulk} />
                    </Button>
                  </a>

                  <hr className="mt-4 mb-4" />

                  <MailingListModal  action="add"
                                     loadAll={() => this.state.handleLoadMailingList().then(mailinglist => this.setState({ mailinglist: this.sortEmailsAlphabetically(mailinglist), strMailingList: mailingListToString(mailinglist) }))} />                  

                  <br className="mb-4" />

                  {this.state.mailinglist.map(subscriber => (
                    <MailingListCard  key={subscriber._id}
                                      data={subscriber}
                                      loadAll={() => this.state.handleLoadMailingList().then(mailinglist => this.setState({ mailinglist: this.sortEmailsAlphabetically(mailinglist), strMailingList: mailingListToString(mailinglist) }))} />
                  ))}
                </>
            }
          </>
        }

          <Snackbar
            anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
            autoHideDuration={3000}
            TransitionComponent={SlideTransition}
            open={this.state.showSnackbar}
            onClose={() => this.handleHideSnackbar()}
            key="client-notification"
          >
            <Alert
              onClose={() => this.handleHideSnackbar()}
              severity="success"
            >
              Mailing list copied to clipboard !
            </Alert>
          </Snackbar>

      </>
    );
  }

}

export default memo(DashboardMailingList);