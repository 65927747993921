import React, { Component } from 'react';
import { Worker } from '@react-pdf-viewer/core';

import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.min.js';
import Routes from './routes';


class App extends Component {
  render() {
    return (
      <Worker workerUrl="https://cdn.jsdelivr.net/npm/pdfjs-dist@3.4.120/build/pdf.worker.js">
        <Routes />
      </Worker>
    );
  }
}

export default App;