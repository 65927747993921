import React, { memo } from 'react';
import {    getRessouresUrl, 
            ellipsify, 
            cleanDisplayFilename,
            prettyPrintDate } from './../../../utils/config';
import NewsModal from './NewsModal';
import PdfViewerModal from './../../../components/pdf-viewer/PdfViewerModal';

import './../../../assets/css/style.css';

const NewsCard = (props) => {
    const news = props.data;
    const pubDate = prettyPrintDate(news.publicationDate);

    return (
        <div className="custom-card card-style-steel-texture card-shadow">
            <div className="text-container">
                <div>
                    {/* Required fields */}
                    <h3>{ news.title }</h3>
                    <span>{pubDate}</span>
                    <br />
                    <br />
                    <p>{ news.description }</p>
                    {/* Optional fields */}
                    {
                        news.newsDocs.length !== 0
                        ?   <div key={news._id+news.title+"docs"}>
                                <br />
                                <p><b>Documents & files</b></p>
                                {news.newsDocs.map((doc, index) => (
                                    <div key={cleanDisplayFilename(doc)+index}>
                                        <PdfViewerModal btnPrompt={cleanDisplayFilename(doc)}
                                                        modalPrompt={cleanDisplayFilename(doc)} 
                                                        ressource={getRessouresUrl()+doc}/>
                                        {index !== (news.newsDocs.length-1)
                                            ? <span><br /></span>
                                            : null
                                        }
                                    </div>
                                ))}
                            </div>
                        : null
                    }
                    {
                        news.newsImages.length !== 0
                        ?   <div key={news._id+news.title+"images"}>
                                <br />
                                <p><b>Media</b></p>
                                {news.newsImages.map((img, index) => (
                                    <img    key={cleanDisplayFilename(img)+index}
                                            src={getRessouresUrl()+img} 
                                            alt={cleanDisplayFilename(img)} 
                                            className="image" />
                                ))}
                            </div>
                        : null
                    }
                    {
                        news.links.length !== 0
                        ?   <div>
                                <br />
                                <p>Links and ressources</p>
                                {news.links.map((data, index) => (
                                    <span key={data.link+index}>
                                        <a  target="_blank" 
                                            rel="noreferrer" 
                                            draggable={false}
                                            href={data.link} >
                                                {data.display
                                                    ? data.display
                                                    : ellipsify(data.link)
                                                }
                                        </a>    
                                        {index !== (news.links.length-1)
                                            ? <span>, </span>
                                            : null
                                        }
                                    </span>))}
                            </div>
                        : null
                    }
                </div>
            </div>
            <div className="buttons-container">
                <NewsModal  id={news._id}
                            loadAll={props.loadAll}
                            news={news}
                            action="edit" />

                <NewsModal  id={news._id}
                            loadAll={props.loadAll}
                            news={news}
                            action="remove" />
            </div>
        </div>);

};

export default memo(NewsCard);
