import React, { Component, memo } from 'react';
import { Tabs, Tab } from 'react-bootstrap';
import EventCard from './EventCard';
import { getApiUrl } from '../../utils/config';
import CircularLoading from '../../components/circular-loading/CircularLoading';

class EventsComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      events: {
        'currentlyHappening': [],
        'upcoming': [],
        'archived': [],
      },
      isLoaded: false
    }
  }

  sortEventsByEventDate = (events) => {
    // Store today's date for comparison
    let today = new Date();

    // Create seperate lists for each event type
    let currentlyHappeningList = [];
    let upcomingList = [];
    let archivedList = [];
    
    // Populate seperate lists based off event start and end date
    Array.from(events).map((event) => {
      if (new Date(event.eventStartDate) <= today && new Date(event.eventEndDate) >= today) 
        currentlyHappeningList = [...currentlyHappeningList, event];

      else if (new Date(event.eventStartDate) > today) 
        upcomingList = [...upcomingList, event];

      else if (new Date(event.eventEndDate) < today)
        archivedList = [...archivedList, event];

      return null
    });

    return {
      'currentlyHappening': currentlyHappeningList,
      'upcoming': upcomingList,
      'archived': archivedList
    }
  }

  componentDidMount() {
    fetch(getApiUrl() + 'events', {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json'
      },
      mode: 'cors',
      cache: 'default',
      credentials: 'omit'

    })
      .then(res => res.json())
      .then(data => {
        this.setState({ 
          isLoaded: true, 
          events: this.sortEventsByEventDate(data.events)
        });
      })
      .catch(error => console.log(error))
  }

  render() {
    const nbCurrentlyHappeningEvents = this.state.events['currentlyHappening'].length;
    const nbUpcomingEvents = this.state.events['upcoming'].length;
    const nbArchivedEvents = this.state.events['archived'].length;

    if (!this.state.isLoaded) {
      return <div><CircularLoading /></div>;
    } else {
      return (  
        <>
          <Tabs
            defaultActiveKey={nbCurrentlyHappeningEvents !== 0 ? "currentlyHappening" : "upcoming"}
            transition
            id="events-tabs"
            className="mb-4"
          >
            { nbCurrentlyHappeningEvents !== 0 
                ? <Tab eventKey="currentlyHappening" title={ "Currently happening events ("+ nbCurrentlyHappeningEvents + ")"}>
                    {this.state.events['currentlyHappening'].map(event => (
                        <EventCard  key={event._id}
                                    type="currentlyHappening"
                                    data={event} />
                    ))}
                  </Tab>
                : null
            }
            <Tab eventKey="upcoming" title={ "Upcoming events ("+ nbUpcomingEvents + ")"}>
              {nbUpcomingEvents !== 0
                ? 
                  <>
                    {this.state.events['upcoming'].map(event => (
                      <EventCard  key={event._id}
                                  type="upcoming"
                                  data={event} />
                    ))}
                  </>
                : <span>No upcoming events found</span>
              }
            </Tab>
            <Tab eventKey="archived" title={ "Archived/old events ("+ nbArchivedEvents + ")"}>
              {nbArchivedEvents !== 0
                ? 
                  <>
                    {this.state.events['archived'].map(event => (
                      <EventCard  key={event._id} 
                                  type="archived"
                                  data={event} />
                    ))}
                  </>
                : <span>No archived/old events found</span>
              }
            </Tab>
          </Tabs>
        </>
      );
    }
  }
}

function Events() {
  return (
    <div>
      <h2>Events</h2>
      <br />
      <EventsComponent />
    </div>
  );
}

export default memo(Events);