import React, { useState, useEffect } from 'react';
import './typewriter.css';

const TypewriterEffect = ({ textContent }) => {
    const [displayText, setDisplayText] = useState('');
    const [charIndex, setCharIndex] = useState(0); 
    const [lineIndex, setLineIndex] = useState(0);
    const [cursorVisible, setCursorVisible] = useState(true); // For cursor blinking

    const lines = textContent.split('\n')

    useEffect(() => {
        if (lineIndex < lines.length) {
            if (charIndex < lines[lineIndex].length) {
                const timer = setTimeout(() => {
                    setDisplayText((prevText) => prevText + lines[lineIndex][charIndex]);
                    setCharIndex(charIndex + 1); // Move to the next character
                }, Math.random() * 20 + 50); // Random typing speed
        
                return () => clearTimeout(timer); // Clean up timer
            } else {
                // Move to the next line after the current one is typed
                setLineIndex(lineIndex + 1);
                setCharIndex(0); // Reset index for the next line
                if (lineIndex !== lines.length-1)
                    setDisplayText((prevText) => prevText + '\n'); 
                else 
                    setDisplayText((prevText) => prevText);
            }
        }
        }, [charIndex, lineIndex, lines]);

    useEffect(() => {
        const cursorTimer = setInterval(() => {
            setCursorVisible((prev) => !prev); // Toggle cursor visibility
        }, 500); // Blink every 500ms

        return () => clearInterval(cursorTimer); // Clear interval on cleanup
    }, []);

    return (
        <pre id="typewriter">
            <h1 className="content-video-container-header">
                <span>{displayText}</span>
                <span className={cursorVisible ? 'cursor-visible' : 'cursor-hidden'}>|</span>
            </h1>
        </pre>
    );
};

export default TypewriterEffect;
