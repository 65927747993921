import React, { memo } from "react";
import {  faLightbulb,
          faGlobe,
          faClipboardCheck } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import './about-us.css';
import './../../assets/css/style.css';
import './../home/home.css';


function AboutUs() {
  return (
    <div>

      <h2>About Us</h2>

      {/* PRESENTATION CARD */}
      <div className="container responsive-container-top">
        <div className="presentation-card about-us-description card-style-steel-texture card-shadow">
          <p>The <b>Alexandria Research Center for Adaptation to Climate Change (ARCA)</b> was established in 2011 through a research initiative funded by IDRC-Canada. Guided by values of excellence, diversity, social responsibility, and integrity, ARCA serves as a leading center for integrated climate change adaptation research. We promote knowledge sharing, foster collaborative, policy-driven research, and facilitate the exchange of experiences and best practices to address the challenges of climate change.</p>
        </div>

      </div>

      <div className="services-presentation-cards">
        {/* VISION CARD */}
        <div className="presentation-card card-shadow card-style-arca-plants">
          <h4>
            <span className="important-title"><FontAwesomeIcon icon={faLightbulb} />&nbsp;&nbsp;</span>
              <b>Our Vision</b>
          </h4>
          <hr />
          <p>Advancing integrated, policy-driven climate change adaptation research, fostering a center of excellence that promotes collaboration, knowledge-sharing, and the exchange of best practices to drive sustainable solutions for the future.</p>
        </div>

        {/* MISSION CARD */}
        <div className="presentation-card card-shadow card-style-arca-sun">
          <h4>
            <span className="important-title"><FontAwesomeIcon icon={faGlobe} />&nbsp;&nbsp;</span>
              <b>Our Mission</b>
          </h4>
          <hr />
          <p>Establishing a multidisciplinary, rigorous hub for climate change adaptation research that empowers cross-cutting decision-making and informs effective policy development.</p>
        </div>
      </div>

      {/* OBJECTIVES CARD */}
      <div className="container responsive-container-bottom">
        <div className="presentation-card card-shadow card-style-arca-waves">
          <h4>
            <span className="important-title"><FontAwesomeIcon icon={faClipboardCheck} />&nbsp;&nbsp;</span>
            <b>Our Objectives</b>
          </h4>
          <hr />
          <ul className="bullet-li">
            <li>
              <p>
                <b>Develop institutional research capacities: </b>
                by establishing robust management structures, strategic plans, evaluation frameworks, and fostering strong partnerships and networks.
              </p>
            </li>
          </ul>
          
          <hr className="slim-hr" />
          
          <ul className="bullet-li">
            <li>
              <p>
                <b>Enhance research competencies: </b>
                through studentship awards, targeted training workshops, and mentoring programs that build technical and research skills.
              </p>
            </li>
          </ul>  
            <hr className="slim-hr" />
          
          <ul className="bullet-li">
            <li>
              <p>
                <b>Support effective policy-research integration: </b>
                by analyzing research needs for policymakers, promoting capacity building, and facilitating networking and communication channels.
              </p>
            </li>
          </ul>  
          
          <hr className="slim-hr" />

          <ul className="bullet-li">
            <li>
              <p>
                <b>Conduct rigorous research to inform decision-making and policy: </b>
                through small grants projects and focused research on the economics of climate change adaptation.
              </p>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
}

export default memo(AboutUs);