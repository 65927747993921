import React, { useState, memo } from 'react';
import { getApiUrl } from '../../../utils/config';
import { getToken } from '../../../session-utils/UserSession';
import { Container, Col, Modal, Button, Form, CloseButton } from 'react-bootstrap';
import { faPencilAlt, faTrash } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import './../../../assets/css/style.css';
import 'react-awesome-button/dist/styles.css';


async function actionMessage(action, credentials) {
    if (action === "edit") {
        return fetch(getApiUrl() + "messages/" + action, {
            method: 'POST',
            headers: {
                'Authorization': 'Bearer ' + getToken(),
                'Content-Type': 'application/json'
            },
            mode: 'cors',
            cache: 'default',
            body: credentials
        })
        .then(data => data.json())
        .then(data => { return data.contactMessage })
        .catch(error => console.log(error))
    } else if (action === "remove") {
        return fetch(getApiUrl() + "messages/" + action, {
            method: 'POST',
            headers: {
                'Authorization': 'Bearer ' + getToken(),
                'Content-Type': 'application/json'
            },
            mode: 'cors',
            cache: 'default',
            body: JSON.stringify({ _id: credentials })
        })
        .then(data => data.json())
        .catch(error => console.log(error))
    }
}

function MessageModal(props) {
    const [modalShow, setModalShow] = useState(false);

    // let [lastName, setLastName] = useState("");
    // let [firstName, setFirstName] = useState("");
    // let [email, setEmail] = useState("");
    let [pending, setPending] = useState("");
    // let [subject, setSubject] = useState("");
    // let [organisation, setOrganisation] = useState("");
    // let [phonenumber, setPhoneNumber] = useState("");
    // let [message, setMessage] = useState("");
    let [notes, setNotes] = useState("");

    let contactMessage = props.contactMessage;

    let modal = {
        header: undefined,
        title: undefined,
        description: undefined,
        button: undefined,
        icon: undefined,
        buttonVariant: undefined,
        size: undefined
    };

    const handleClose = () => {
        setModalShow(false);

        // setLastName("");
        // setFirstName("");
        // setEmail("");
        setPending("");
        // setSubject("");
        // setOrganisation("");
        // setPhoneNumber("");
        // setMessage("");
        setNotes("");
    }

    const handleShow = () => {
        // Set contact message attributes in State variables
        // setLastName(contactMessage.lastName);
        // setFirstName(contactMessage.firstName);
        // setEmail(contactMessage.email);
        setPending(contactMessage.pending);
        // setSubject(contactMessage.subject);
        // setOrganisation(contactMessage.organisation);
        // setPhoneNumber(contactMessage.phonenumber);
        // setMessage(contactMessage.message);
        setNotes(contactMessage.notes);

        setModalShow(true);
    }

    const handleActionMessage = async e => {
        e.preventDefault();

        let reqData = {
            "contactMessage": {
                _id: props.id,
                // lastName,
                // firstName,
                // email,
                pending,
                // subject,
                // organisation,
                // phonenumber,
                // message,
                notes
            }
        }

        // edit mode
        let thingData = JSON.stringify(reqData);
        
        if (props.action === "remove") {
            thingData = props.id;
        }

        await actionMessage(props.action, thingData);
        handleClose();
        props.loadAll();
    }

    if (props.action === "edit") {
        modal.classStyle = "mb-3";
        modal.header = "Edit your notes";
        modal.title = "";
        modal.description = "";
        modal.button = "Notes";
        modal.icon = faPencilAlt;
        modal.buttonVariant = "warning";
        modal.size = "md";

    } else if (props.action === "remove") {
        modal.classStyle = "";
        modal.header = "Delete message";
        modal.title = "Are you sure you want to delete this received message?";
        modal.description = "This message will be permanently deleted, this action cannot be undone.";
        modal.button = "Delete";
        modal.icon = faTrash;
        modal.buttonVariant = "danger";
        modal.size = "md";
    }

    const btnVariant = "outline-" + modal.buttonVariant;

    return (
        <>
            <Button variant={btnVariant}
                    className={modal.classStyle}
                    onClick={() => handleShow()}>
                {modal.button}&nbsp;&nbsp;
                <FontAwesomeIcon icon={modal.icon} />
            </Button>

            <Modal  show={modalShow}
                    size={modal.size}
                    onHide={handleClose}
                    backdrop="static"
                    aria-labelledby="contained-modal-title-vcenter"
                    centered >
                <form   onSubmit={handleActionMessage} 
                        encType="multipart/form-data" 
                        className="card-style-steel-texture card-modal">
                    <Modal.Header>
                        <Modal.Title id="contained-modal-title-vcenter">
                            {modal.header}
                        </Modal.Title>
                        <CloseButton    aria-label="Hide popup" 
                                        onClick={() => handleClose()} />
                    </Modal.Header>
                    <Modal.Body>
                        {modal.title
                            ? <h5>{modal.title}</h5>
                            : null
                        }
                        {modal.description
                            ? <p>{modal.description}</p>
                            : null
                        }
                        {props.action === "edit"
                            ?
                            <Container className="custom-modal">
                                {/* <div className="container">
                                    <Col>
                                        <Form.Group className="mb-3">
                                            <Form.Label>
                                                <b>Sender's first Name</b> <span className='important-text'>*</span>
                                            </Form.Label>
                                            <Form.Control   type="text"
                                                            name="firstName"
                                                            value={firstName}
                                                            onChange={e => setFirstName(e.target.value)}
                                                            disabled />
                                        </Form.Group>

                                        <Form.Group className="mb-3">
                                            <Form.Label>
                                                <b>Sender's last Name</b> <span className='important-text'>*</span>
                                            </Form.Label>
                                            <Form.Control   type="text"
                                                            name="lastName"
                                                            value={lastName}
                                                            onChange={e => setLastName(e.target.value)}
                                                            disabled />
                                        </Form.Group>

                                        <Form.Group className="mb-3">
                                            <Form.Label>
                                                <b>Sender's email</b> <span className='important-text'>*</span>
                                            </Form.Label>
                                            <Form.Control   type="text"
                                                            name="email"
                                                            value={email}
                                                            onChange={e => setEmail(e.target.value)} 
                                                            disabled />
                                        </Form.Group>

                                        <Form.Group className="mb-3">
                                            <Form.Label>
                                                <b>Sender's phone number</b>
                                            </Form.Label>
                                            <Form.Control   type="text"
                                                            name="phonenumber"
                                                            value={phonenumber}
                                                            onChange={e => setPhoneNumber(e.target.value)} 
                                                            disabled />
                                        </Form.Group>

                                        <Form.Group className="mb-3">
                                            <Form.Label>
                                                <b>Sender's organisation</b>
                                            </Form.Label>
                                            <Form.Control   type="text"
                                                            name="organisation"
                                                            value={organisation}
                                                            onChange={e => setOrganisation(e.target.value)} 
                                                            disabled />
                                        </Form.Group>

                                        <Form.Group className="mb-3">
                                            <Form.Label>
                                                <b>Subject</b>
                                            </Form.Label>
                                            <Form.Control   type="text"
                                                            name="subject"
                                                            value={subject}
                                                            onChange={e => setSubject(e.target.value)} 
                                                            disabled />
                                        </Form.Group>

                                        <Form.Group className="mb-3">
                                            <Form.Label>
                                                <b>Message content</b> <span className='important-text'>*</span>
                                            </Form.Label>
                                            <Form.Control 
                                                type="text"
                                                as="textarea" 
                                                rows={7}
                                                name="message"
                                                value={message}
                                                onChange={e => setMessage(e.target.value)}
                                                disabled />
                                        </Form.Group>
                                    </Col>
                            </div> */}
                            <div className="container">
                                <Col>
                                        <Form.Group className="mb-3">
                                            <Form.Label>
                                                <b>Message status</b> <span className='important-text'>*</span>
                                            </Form.Label>
                                            <Form.Select    value={pending} 
                                                            onChange={e => setPending((e.target.value === 'true'))}>
                                                <option key="pending" value="true">Pending</option>
                                                <option key="responded" value="false">Responded</option>
                                            </Form.Select>
                                        </Form.Group>

                                        <Form.Group className="mb-3">
                                            <Form.Label>
                                                <b>Your notes</b>
                                            </Form.Label>
                                            <Form.Control 
                                                type="text"
                                                as="textarea" 
                                                rows={7}
                                                name="notes"
                                                value={notes}
                                                onChange={e => setNotes(e.target.value)} 
                                                autoFocus />
                                        </Form.Group>
                                    </Col>
                                </div>
                            </Container>
                            : <></>
                        }
                    </Modal.Body>
                    <Modal.Footer>
                        <Button 
                            variant="secondary"
                            style={{ width: '100px' }}
                            onClick={() => handleClose()}>
                            Close
                        </Button>
                        <Button 
                            variant={modal.buttonVariant}
                            style={{ width: '100px' }}
                            type="submit" >
                            {props.action.charAt(0).toUpperCase().concat(props.action.substr(1))}
                        </Button>
                    </Modal.Footer>
                </form>
            </Modal>
        </>
    );
}

export default memo(MessageModal);
