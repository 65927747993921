import React, { Component, memo } from "react";
import NewsCard from './NewsCard';
import NewsModal from './NewsModal';
import { getApiUrl } from '../../../utils/config';
import CircularLoading from '../../../components/circular-loading/CircularLoading';


async function loadNews() {
  return fetch(getApiUrl() + "news/", {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json'
    },
    mode: 'cors',
    cache: 'default',
    credentials: 'omit'

  })
    .then(res => res.json())
    .then(data => { return data.news })
    .catch(error => console.log(error))
}

function DashboardNews() {
  const handleLoadNews = async () => {
    return await loadNews();
  }

  return <NewsComponent handleLoadNews={handleLoadNews} />
}

class NewsComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loadNews: [],
      handleLoadNews: props.handleLoadNews,
      isLoaded: false
    }
  }

  sortNewsByPublicationDate = (news) => {
    return news.sort((a, b) => (new Date(a.publicationDate) > new Date(b.publicationDate)) ? -1 : ((new Date(a.publicationDate) < new Date(b.publicationDate)) ? 1 : 0))
  }

  componentDidMount() {
    this.state.handleLoadNews().then(news => {
      this.setState({ 
        news: this.sortNewsByPublicationDate(news), 
        isLoaded: true 
      })
    }).catch(error => console.log(error))
  }

  render() {
    return (
      <>
        <h2>Manage news articles</h2>

        {!this.state.isLoaded
          ? <div><CircularLoading /></div>
          :
          <>
            {!this.state.news
              ? <div>No news articles found</div>
              : <>
                  <NewsModal  action="add"
                              loadAll={() => this.state.handleLoadNews().then(news => this.setState({ news: this.sortNewsByPublicationDate(news) }))} />
                  
                  {this.state.news.map(newsarticle => (
                    <NewsCard key={newsarticle._id}
                              data={newsarticle}
                              loadAll={() => this.state.handleLoadNews().then(news => this.setState({ news: this.sortNewsByPublicationDate(news) }))} />
                  ))}
                </>
            }
          </>
        }
      </>
    );
  }

}

export default memo(DashboardNews);