import React, { Component, memo } from "react";
import { Tabs, Tab } from 'react-bootstrap';
import EventCard from './EventCard';
import EventModal from './EventModal';
import { getApiUrl } from '../../../utils/config';
import CircularLoading from '../../../components/circular-loading/CircularLoading';


async function loadEvents() {
  return fetch(getApiUrl() + "events/", {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json'
    },
    mode: 'cors',
    cache: 'default',
    credentials: 'omit'

  })
    .then(res => res.json())
    .then(data => { return data.events })
    .catch(error => console.log(error))
}

function DashboardEvents() {
  const handleLoadEvents = async () => {
    return await loadEvents();
  }

  return <EventsComponent handleLoadEvents={handleLoadEvents} />
}

class EventsComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      events: {
        'currentlyHappening': [],
        'upcoming': [],
        'archived': [],
      },
      handleLoadEvents: props.handleLoadEvents,
      isLoaded: false
    }
  }

  sortEventsByEventDate = (events) => {
    // Store today's date for comparison
    let today = new Date();

    // Create seperate lists for each event type
    let currentlyHappeningList = [];
    let upcomingList = [];
    let archivedList = [];
    
    // Populate seperate lists based off event start and end date
    Array.from(events).map((event) => {
      if (new Date(event.eventStartDate) <= today && new Date(event.eventEndDate) >= today) 
        currentlyHappeningList = [...currentlyHappeningList, event];

      else if (new Date(event.eventStartDate) > today) 
        upcomingList = [...upcomingList, event];

      else if (new Date(event.eventEndDate) < today)
        archivedList = [...archivedList, event];

      return null
    });

    return {
      'currentlyHappening': currentlyHappeningList,
      'upcoming': upcomingList,
      'archived': archivedList
    }
  }

  componentDidMount() {
    this.state.handleLoadEvents().then(events => {
      this.setState({ 
        events: this.sortEventsByEventDate(events),
        isLoaded: true 
      })
    }).catch(error => console.log(error))
  }

  render() {
    const nbCurrentlyHappeningEvents = this.state.events['currentlyHappening'].length;
    const nbUpcomingEvents = this.state.events['upcoming'].length;
    const nbArchivedEvents = this.state.events['archived'].length;

    return (
      <>
        <h2>Manage events</h2>

        {!this.state.isLoaded
          ? <div><CircularLoading /></div>
          : <>
            <EventModal action="add"
                loadAll={() => this.state.handleLoadEvents().then(events => this.setState({ events: this.sortEventsByEventDate(events) }))} />
    
            <Tabs
              defaultActiveKey={nbCurrentlyHappeningEvents !== 0 ? "currentlyHappening" : "upcoming"}
              transition
              id="events-tabs"
              className="mt-4 mb-4"
            >
              { nbCurrentlyHappeningEvents !== 0 
                  ? <Tab eventKey="currentlyHappening" title={ "Currently happening events ("+ nbCurrentlyHappeningEvents + ")"}>
                      {this.state.events['currentlyHappening'].map(event => (
                        <EventCard  key={event._id}
                                    data={event}
                                    type="currentlyHappening"
                                    loadAll={() => this.state.handleLoadEvents().then(events => this.setState({ events: this.sortEventsByEventDate(events) }))} />
                      ))}
                    </Tab>
                  : null
              }
              <Tab eventKey="upcoming" title={ "Upcoming events ("+ nbUpcomingEvents + ")"}>
                {nbUpcomingEvents !== 0
                  ? 
                    <>
                      {this.state.events['upcoming'].map(event => (
                        <EventCard  key={event._id}
                                    data={event}
                                    type="upcoming"
                                    loadAll={() => this.state.handleLoadEvents().then(events => this.setState({ events: this.sortEventsByEventDate(events) }))} />
                      ))}
                    </>
                  : <span>No upcoming events found</span>
                }
              </Tab>
              <Tab eventKey="archived" title={ "Archived/old events ("+ nbArchivedEvents + ")"}>
                {nbArchivedEvents !== 0
                  ? 
                    <>
                      {this.state.events['archived'].map(event => (
                        <EventCard  key={event._id}
                                    data={event}
                                    type="archived"
                                    loadAll={() => this.state.handleLoadEvents().then(events => this.setState({ events: this.sortEventsByEventDate(events) }))} />
                      ))}
                    </>
                  : <span>No archived/old events found</span>
                }
              </Tab>
            </Tabs>
            </>
        }
      </>
    );
  }

}

export default memo(DashboardEvents);