import React, { useState, memo, useEffect } from 'react';
import {    getApiUrl, 
            getRessouresUrl, 
            ellipsify, 
            cleanDisplayFilename, 
            isValidHttpUrl, 
            humanReadableFileSize } from './../../../utils/config';
import { getToken } from './../../../session-utils/UserSession';
import { Container, Col, Row, Modal, Button, Form, CloseButton } from 'react-bootstrap';
import { faPlus, faPencilAlt, faTrash, faUpload } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import './../../../assets/css/style.css';
import 'react-awesome-button/dist/styles.css';


async function actionMember(action, credentials) {
    if (action === "add" || action === "edit") {
        return fetch(getApiUrl() + "team/" + action, {
            method: 'POST',
            headers: {
                'Authorization': 'Bearer ' + getToken()
            },
            body: credentials
        })
        .then(data => data.json())
        .then(data => { return data.member })
        .catch(error => console.log(error))
    } else if (action === "remove") {
        return fetch(getApiUrl() + "team/" + action, {
            method: 'POST',
            headers: {
                'Authorization': 'Bearer ' + getToken(),
                'Content-Type': 'application/json'
            },
            mode: 'cors',
            cache: 'default',
            body: JSON.stringify({ _id: credentials })
        })
        .then(data => data.json())
        .catch(error => console.log(error))
    }
}

async function createTempFileFromURL(mediaURL, filename) {
    let response = await fetch(mediaURL);
    let data = await response.blob();
    let metadata = {
      type: 'application/pdf'
    };
    
    return new File([data], filename, metadata);
}

function TeamModal(props) {
    const [modalShow, setModalShow] = useState(false);
    const [formFileUploadRef, setFormFileUploadRef] = useState(React.createRef(null));

    let [appearanceOrder, setAppearanceOrder] = useState(props.nbMembers);
    let [name, setName] = useState("");
    let [title, setTitle] = useState("");
    let [role, setRole] = useState("");
    let [mail, setMail] = useState("");
    let [bio, setBio] = useState("");
    let [cvs, setCVs] = useState([]);
    let [deleteCVs, setDeleteCVs] = useState([]);
    let [links, setLinks] = useState([]);

    let [mediaLink, setMediaLink] = useState("");
    let [displayMediaLink, setDisplayMediaLink] = useState("");

    let member = props.member;
    if (props.member === undefined || props.member === null) {
        // In Addititon Mode
        member = {
            appearanceOrder: props.nbMembers,
            name: "",
            title: "",
            role: "",
            mail: "",
            bio: "",
            cvs: [],
            links: []
        }
    } 

    let modal = {
        header: undefined,
        title: undefined,
        description: undefined,
        button: undefined,
        icon: undefined,
        buttonVariant: undefined,
        size: undefined
    };

    const handleAddLink = e => {
        e.preventDefault();

        let linkItem = {
            link: '',
            display: ''
        }

        if (mediaLink !== undefined) {
            if (mediaLink.trim().length !== 0) {
                linkItem.link = mediaLink.trim();
                if (displayMediaLink !== undefined) {
                    if (displayMediaLink.trim().length !== 0) {
                        linkItem.display = displayMediaLink.trim();
                    }
                }
                // Add link item to links clone list 
                setLinks([...links, linkItem]);
            }
        }

        setMediaLink("");
        setDisplayMediaLink("");
    }

    const handleRemoveLink = (e, index) => {
        e.preventDefault();

        const temp_links = [...links];
        temp_links.splice(index, 1);
        setLinks(temp_links);
    }

    const linksList = links.map((data, index) => (
        <div key={data.link+index}>
            <Row>
                <Col sm={9} style={{ padding: '0px 32px' }}>
                    <table><tbody>
                        <tr>
                            <td>
                                <span>
                                    <b>Link: </b>
                                    <a  href={data.link}
                                        title={data.link}
                                        target="_blank"
                                        rel="noreferrer">
                                            {ellipsify(data.link)}
                                    </a>
                                </span>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <b>Display as: </b>
                                {(data.display !== undefined || data.display !== '')
                                    ? ellipsify(data.display)
                                    : "None"
                                }
                            </td>
                        </tr>
                    </tbody></table>
                </Col>
                <Col sm={3} style={{ padding: '0px', textAlign: 'center' }}>
                    <Button variant="outline-danger"
                            style={{ borderRadius: "15px" }}
                            onClick={e => handleRemoveLink(e, index)}>
                        <FontAwesomeIcon icon={faTrash} />
                    </Button>
                </Col>
            </Row>
            {index !== (links.length - 1)
                ? <hr className="slim-hr"/>
                : null
            }
        </div>
    ));

    const handleAutomaticFormFileInputClick = () => {
        formFileUploadRef.current.click()
    }

    const handleAddCVs = (e) => {
        e.preventDefault();

        const targetFiles = Array.prototype.slice.call(e.target.files);
        const cvFiles = [...cvs];
        targetFiles.some((file) => {
            // Check if file does not already exist in files list
            if (cvFiles.findIndex((f) => f.name === file.name) === -1
                && cvFiles.findIndex((f) => cleanDisplayFilename(f.name) === file.name) === -1) {
                    if (file.type === "application/pdf") {
                        cvFiles.push(file);
                    } else {
                        alert("Please provide a valid PDF document");
                    }
            } else {
                alert(`The document entitled ${file.name} is already included in the list`);
            }
            return null;
        })

        setCVs(cvFiles);
        e.target.value = '';
    }

    const handleRemoveCV = (e, index) => {
        e.preventDefault();

        const cvFiles = [...cvs];
        const deletedCvName = cvFiles[index].name;
        cvFiles.splice(index, 1);

        setCVs(cvFiles);

        // Check if deleted CV is already a stored file or a temporary addition
        if (deletedCvName.includes("uploads/team/"))
            setDeleteCVs([...deleteCVs, deletedCvName]);
        
        e.target.value = '';
    }

    const cvsList = Array.from(cvs).map((file, index) => (
        <div key={file.name}>
            <Row>
                <Col sm={9} style={{ padding: '0px 32px' }}>
                    <table><tbody>
                        <tr>
                            <td>
                                <span>
                                    <b>File name: </b>
                                    <a  href={URL.createObjectURL(file)}
                                        title={cleanDisplayFilename(file.name)}
                                        target="_blank"
                                        rel="noreferrer">
                                            {cleanDisplayFilename(file.name)}
                                    </a>
                                </span>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <b>File size: </b>
                                {humanReadableFileSize(file.size)}
                            </td>
                        </tr>
                    </tbody></table>
                </Col>
                <Col sm={3} style={{ padding: '0px', textAlign: 'center' }}>
                    <Button variant="outline-danger"
                            style={{ borderRadius: "15px" }}
                            onClick={e => handleRemoveCV(e, index)}>
                        <FontAwesomeIcon icon={faTrash} />
                    </Button>
                </Col>
            </Row>
            {index !== (cvs.length - 1)
                ? <hr className="slim-hr"/>
                : null
            }
        </div>
    ));

    const handleClose = () => {
        setModalShow(false);
        setFormFileUploadRef(React.createRef(null));
        
        setAppearanceOrder(0);
        setName("");
        setTitle("");
        setRole("");
        setMail("");
        setBio("");
        setCVs([]);
        setDeleteCVs([]);
        setLinks([]);
        setMediaLink("");
        setDisplayMediaLink("");
    }

    const setFilesFromURL = async (files) => {
        if (props.action === "edit") {
            // Set CVs with Files array instead of Paths array
            if (files !== undefined && files.length !== 0) {
                // Create File items based off stored file paths
                const loadedCVs = [...files];
                var cvFiles = [];
                loadedCVs.forEach(async (filepath) => { 
                    await createTempFileFromURL(getRessouresUrl()+filepath, filepath)
                    .then((file) => cvFiles.push(file))
                    .catch((error) => console.log(error))
                    setCVs([...cvFiles])
                });
            } else {
                setCVs(files)
            }
        }
    }

    useEffect((files) => {
        setFilesFromURL(files? member.cvs : []);
        
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const handleShow = () => {
        // Set member attributes in State variables
        setAppearanceOrder(member.appearanceOrder)
        setName(member.name);
        setTitle(member.title);
        setRole(member.role);
        setMail(member.mail);
        setBio(member.bio);
        setLinks(member.links);
        // set CVs
        setFilesFromURL(member.cvs);
        setDeleteCVs([]);
        setModalShow(true);
    }

    const handleActionMember = async e => {
        e.preventDefault();

        let temp;
        if (props.action === "add") {
            temp = {
                appearanceOrder,
                name,
                title,
                role,
                mail,
                bio,
                links
            }
        } else if (props.action === "edit") {
            temp = {
                _id: props.id,
                appearanceOrder,
                name,
                title,
                role,
                mail,
                bio,
                links
            }
        } 

        const member = temp;
        let thingData = new FormData();

        if (props.action === "add" || props.action === "edit") {
            thingData.append('member', JSON.stringify(member));

            // Files to upload
            if (cvs !== undefined) {
                Array.from(cvs).map((cv) => {
                    
                    // If file includes path to file storage, then ignore.
                    // (This means that the file has already been uploaded and stored.)
                    if (cv.name.includes('uploads/team/')){
                        return null;
                    }

                    thingData.append('team', cv, cv.name);
                    return null;
                });
            }

            // List of file (paths) to delete
            if (deleteCVs !== undefined) {
                thingData.append('deletefiles', JSON.stringify([...deleteCVs]));
            }

        } else if (props.action === "remove") {
            thingData = props.member._id;
        }

        await actionMember(props.action, thingData);
        handleClose();
        props.loadAll();
    }

    if (props.action === "add") {
        modal.header = "Add new team member";
        modal.title = "";
        modal.description = "";
        modal.button = "Add new team member";
        modal.icon = faPlus;
        modal.buttonVariant = "primary";
        modal.size = "xl";

    } else if (props.action === "edit") {
        modal.header = "Edit member information";
        modal.title = "";
        modal.description = "";
        modal.button = "Edit";
        modal.icon = faPencilAlt;
        modal.buttonVariant = "warning";
        modal.size = "xl";

    } else if (props.action === "remove") {
        modal.header = "Delete member";
        modal.title = "Are you sure you want to delete this member?";
        modal.description = "This member will be permanently deleted, this action cannot be undone.";
        modal.button = "Delete";
        modal.icon = faTrash;
        modal.buttonVariant = "danger";
        modal.size = "md";
    }

    const btnVariant = "outline-" + modal.buttonVariant;

    return (
        <>
            <Button variant={btnVariant}
                    onClick={() => handleShow()}>
                {modal.button}&nbsp;&nbsp;
                <FontAwesomeIcon icon={modal.icon} />
            </Button>

            <Modal  show={modalShow}
                    size={modal.size}
                    onHide={handleClose}
                    backdrop="static"
                    aria-labelledby="contained-modal-title-vcenter"
                    centered >
                <form   onSubmit={handleActionMember} 
                        encType="multipart/form-data" 
                        className="card-style-steel-texture card-modal">
                    <Modal.Header>
                        <Modal.Title id="contained-modal-title-vcenter">
                            {modal.header}
                        </Modal.Title>
                        <CloseButton    aria-label="Hide popup" 
                                        onClick={() => handleClose()} />
                    </Modal.Header>
                    <Modal.Body>
                        {modal.title
                            ? <h5>{modal.title}</h5>
                            : null
                        }
                        {modal.description
                            ? <p>{modal.description}</p>
                            : null
                        }
                        {(props.action === "add" || props.action === "edit")
                            ?
                            <Container className="custom-modal">
                                <div className="modal-container-xlg">
                                    <Col>
                                        <Form.Group className="mb-3">
                                            <Form.Label>
                                                <b>Appearance Order</b> <span className='important-text'>*</span>
                                            </Form.Label>
                                            <Form.Select    value={appearanceOrder} 
                                                            onChange={e => setAppearanceOrder(e.target.value)}>
                                                {Array.from(Array(props.nbMembers), (e, i) => {
                                                    return <option key={i+1} value={i+1}>
                                                        {i+1}
                                                    </option>
                                                })}
                                            </Form.Select>
                                        </Form.Group>
                                        <Form.Group className="mb-3">
                                            <Form.Label>
                                                <b>Full name</b> <span className='important-text'>*</span>
                                            </Form.Label>
                                            <Form.Control   type="text"
                                                            name="name"
                                                            value={name}
                                                            onChange={e => setName(e.target.value)}
                                                            autoFocus required />
                                        </Form.Group>
                                        <Form.Group className="mb-3">
                                            <Form.Label>
                                                <b>Title</b> <span className='important-text'>*</span>
                                            </Form.Label>
                                            <Form.Control   type="text"
                                                            name="title"
                                                            value={title}
                                                            onChange={e => setTitle(e.target.value)}
                                                            required />
                                        </Form.Group>
                                        <Form.Group className="mb-3">
                                            <Form.Label>
                                                <b>Role</b> <span className='important-text'>*</span>
                                            </Form.Label>
                                            <Form.Control   type="text" 
                                                            name="role"
                                                            value={role}
                                                            onChange={e => setRole(e.target.value)}
                                                            required />
                                        </Form.Group>
                                        <Form.Group className="mb-3">
                                            <Form.Label>
                                                <b>Email</b>
                                            </Form.Label>
                                            <Form.Control   type="email" 
                                                            name="mail"
                                                            value={mail}
                                                            onChange={e => setMail(e.target.value)} />
                                        </Form.Group>
                                        <Form.Group className="mb-3">
                                            <Form.Label>
                                                <b>Bio</b>
                                            </Form.Label>
                                            <Form.Control 
                                                type="text"
                                                as="textarea" 
                                                rows={3}
                                                name="bio"
                                                value={bio}
                                                onChange={e => setBio(e.target.value)}
                                            />
                                        </Form.Group>
                                    </Col>
                                </div>
                                <div className="modal-container">
                                    <Col>
                                        <Form.Group className="mb-3">
                                            <Form.Label><b>CV(s)</b></Form.Label>
                                            <Form.Control 
                                                type="file"
                                                ref={formFileUploadRef}
                                                name="team"
                                                accept="application/pdf"
                                                onChange={e => handleAddCVs(e)}
                                                multiple />
                                            {/* <br /> */}
                                            <Button variant="outline-primary"
                                                    style={{ width: "100%", borderRadius: "10px" }}
                                                    onClick={e => handleAutomaticFormFileInputClick(e)}>
                                                Upload files&nbsp;&nbsp;
                                                <FontAwesomeIcon icon={faUpload} />
                                            </Button>
                                        </Form.Group>
                                        {/* Display added pdf documents (if there are any) */}
                                        {(cvs !== undefined && cvs.length !== 0)
                                            ? <>{cvsList}<hr /></>
                                            : null
                                        }
                                        <Form.Group className="mb-3">
                                            <Row>
                                                <Col sm={12}>
                                                    <Form.Label>
                                                        <b>Links and resources</b>
                                                    </Form.Label>
                                                    <Form.Control 
                                                        type="url" 
                                                        name="mediaLink"
                                                        value={mediaLink}
                                                        placeholder="Resource URL or link *"
                                                        // format
                                                        onChange={e => setMediaLink(e.target.value)} />
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col sm={9}>
                                                    <Form.Control 
                                                        type="text" 
                                                        name="displayMediaLink"
                                                        value={displayMediaLink}
                                                        placeholder="Display text (optional)"
                                                        onChange={e => setDisplayMediaLink(e.target.value)} />
                                                </Col>
                                                <Col sm={3} style={{ padding: '0px', textAlign: 'center' }}>
                                                    <Button 
                                                        variant="outline-primary"
                                                        disabled={mediaLink.length === 0 || !isValidHttpUrl(mediaLink)}
                                                        onClick={e => handleAddLink(e)}>
                                                        {mediaLink.length === 0 || !isValidHttpUrl(mediaLink)
                                                            ? <></>
                                                            : <>Add&nbsp;&nbsp;</>
                                                        }
                                                        <FontAwesomeIcon icon={faPlus} />
                                                    </Button>
                                                </Col>
                                            </Row>
                                            {/* Display added links (if there are any) */}
                                            {(links !== undefined && links.length !== 0)
                                                ? <><br />{linksList}</>
                                                : null
                                            }
                                        </Form.Group>
                                    </Col>
                                </div>
                            </Container>
                            : <></>
                        }
                    </Modal.Body>
                    <Modal.Footer>
                        <Button 
                            variant="secondary"
                            style={{ width: '100px' }}
                            onClick={() => handleClose()}>
                            Close
                        </Button>
                        <Button 
                            variant={modal.buttonVariant}
                            style={{ width: '100px' }}
                            type="submit" >
                            {props.action.charAt(0).toUpperCase().concat(props.action.substr(1))}
                        </Button>
                    </Modal.Footer>
                </form>
            </Modal>
        </>
    );
}

export default memo(TeamModal);
