import React, { useState, memo, useEffect } from 'react';
import {    getApiUrl, 
            getRessouresUrl, 
            ellipsify, 
            cleanDisplayFilename, 
            isValidHttpUrl, 
            humanReadableFileSize } from '../../../utils/config';
import { getToken } from '../../../session-utils/UserSession';
import { Container, Col, Row, Modal, Button, Form, CloseButton } from 'react-bootstrap';
import { faPlus, faPencilAlt, faTrash, faUpload } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import './../../../assets/css/style.css';
import 'react-awesome-button/dist/styles.css';


async function actionEvent(action, credentials) {
    if (action === "add" || action === "edit") {
        return fetch(getApiUrl() + "events/" + action, {
            method: 'POST',
            headers: {
                'Authorization': 'Bearer ' + getToken()
            },
            body: credentials
        })
        .then(data => data.json())
        .then(data => { return data.event })
        .catch(error => console.log(error))
    } else if (action === "remove") {
        return fetch(getApiUrl() + "events/" + action, {
            method: 'POST',
            headers: {
                'Authorization': 'Bearer ' + getToken(),
                'Content-Type': 'application/json'
            },
            mode: 'cors',
            cache: 'default',
            body: JSON.stringify({ _id: credentials })
        })
        .then(data => data.json())
        .catch(error => console.log(error))
    }
}

async function createTempFileFromURL(mediaURL, filename) {
    let response = await fetch(mediaURL);
    let data = await response.blob();
    let metadata = {
      type: 'application/pdf'
    };
    
    return new File([data], filename, metadata);
}

function EventModal(props) {
    const [modalShow, setModalShow] = useState(false);
    const [formFileUploadRef, setFormFileUploadRef] = useState(React.createRef(null));
    const [formImgUploadRef, setFormImgeUploadRef] = useState(React.createRef(null));

    let [title, setTitle] = useState("");
    let [eventStartDate, setEventStartDate] = useState("");
    let [eventEndDate, setEventEndDate] = useState("");
    let [eventStartTime, setEventStartTime] = useState("");
    let [eventEndTime, setEventEndTime] = useState("");
    let [address, setAddress] = useState("");
    let [description, setDescription] = useState("");
    let [eventDocs, setEventDocs] = useState([]);
    let [deleteEventDocs, setDeleteEventDocs] = useState([]);
    let [eventImages, setEventImages] = useState([]);
    let [deleteEventImages, setDeleteEventImages] = useState([]);
    let [links, setLinks] = useState([]);

    let [mediaLink, setMediaLink] = useState("");
    let [displayMediaLink, setDisplayMediaLink] = useState("");

    let event = props.event;
    if (props.event === undefined || props.event === null) {
        // In Addititon Mode
        event = {
            title: "",
            eventStartDate: "",
            eventEndDate: "",
            eventStartTime: "",
            eventEndTime: "",
            address: "",
            description: "",
            eventDocs: [],
            eventImages: [],
            links: []
        }
    } 

    let modal = {
        header: undefined,
        title: undefined,
        description: undefined,
        button: undefined,
        icon: undefined,
        buttonVariant: undefined,
        size: undefined
    };

    const handleAddLink = e => {
        e.preventDefault();

        let linkItem = {
            link: '',
            display: ''
        }

        if (mediaLink !== undefined) {
            if (mediaLink.trim().length !== 0) {
                linkItem.link = mediaLink.trim();
                if (displayMediaLink !== undefined) {
                    if (displayMediaLink.trim().length !== 0) {
                        linkItem.display = displayMediaLink.trim();
                    }
                }
                // Add link item to links clone list 
                setLinks([...links, linkItem]);
            }
        }

        setMediaLink("");
        setDisplayMediaLink("");
    }

    const handleRemoveLink = (e, index) => {
        e.preventDefault();

        const temp_links = [...links];
        temp_links.splice(index, 1);
        setLinks(temp_links);
    }

    const linksList = links.map((data, index) => (
        <div key={data.link+index}>
            <Row>
                <Col sm={9} style={{ padding: '0px 32px' }}>
                    <table><tbody>
                        <tr>
                            <td>
                                <span>
                                    <b>Link: </b>
                                    <a  href={data.link}
                                        title={data.link}
                                        target="_blank"
                                        rel="noreferrer">
                                            {ellipsify(data.link)}
                                    </a>
                                </span>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <b>Display as: </b>
                                {(data.display !== undefined || data.display !== '')
                                    ? ellipsify(data.display)
                                    : "None"
                                }
                            </td>
                        </tr>
                    </tbody></table>
                </Col>
                <Col sm={3} style={{ padding: '0px', textAlign: 'center' }}>
                    <Button variant="outline-danger"
                            style={{ borderRadius: "15px" }}
                            onClick={e => handleRemoveLink(e, index)}>
                        <FontAwesomeIcon icon={faTrash} />
                    </Button>
                </Col>
            </Row>
            {index !== (links.length - 1)
                ? <hr className="slim-hr"/>
                : null
            }
        </div>
    ));

    const handleAutomaticFormDocFileInputClick = () => {
        formFileUploadRef.current.click()
    }

    const handleAutomaticFormImgFileInputClick = () => {
        formImgUploadRef.current.click()
    }

    const handleAddDocs = (e) => {
        e.preventDefault();

        const targetFiles = Array.prototype.slice.call(e.target.files);
        const docFiles = [...eventDocs];
        targetFiles.some((file) => {
            // Check if file does not already exist in files list
            if (docFiles.findIndex((f) => f.name === file.name) === -1
                && docFiles.findIndex((f) => cleanDisplayFilename(f.name) === file.name) === -1) {
                    if (file.type === "application/pdf") {
                        docFiles.push(file);
                    } else {
                        alert("Please provide a valid PDF document");
                    }
            } else {
                alert(`The document entitled ${file.name} is already included in the list`);
            }
            return null;
        })

        setEventDocs(docFiles);
        e.target.value = '';
    }

    const handleRemoveDoc = (e, index) => {
        e.preventDefault();

        const docFiles = [...eventDocs];
        const deletedDocName = docFiles[index].name;
        docFiles.splice(index, 1);

        setEventDocs(docFiles);

        // Check if deleted CV is already a stored file or a temporary addition
        if (deletedDocName.includes("uploads/events/"))
            setDeleteEventDocs([...deleteEventDocs, deletedDocName]);
        
        e.target.value = '';
    }

    const docsList = Array.from(eventDocs).map((file, index) => (
        <div key={file.name}>
            <Row>
                <Col sm={9} style={{ padding: '0px 32px' }}>
                    <table><tbody>
                        <tr>
                            <td>
                                <span>
                                    <b>File name: </b>
                                    <a  href={URL.createObjectURL(file)}
                                        title={cleanDisplayFilename(file.name)}
                                        target="_blank"
                                        rel="noreferrer">
                                            {cleanDisplayFilename(file.name)}
                                    </a>
                                </span>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <b>File size: </b>
                                {humanReadableFileSize(file.size)}
                            </td>
                        </tr>
                    </tbody></table>
                </Col>
                <Col sm={3} style={{ padding: '0px', textAlign: 'center' }}>
                    <Button variant="outline-danger"
                            style={{ borderRadius: "15px" }}
                            onClick={e => handleRemoveDoc(e, index)}>
                        <FontAwesomeIcon icon={faTrash} />
                    </Button>
                </Col>
            </Row>
            {index !== (eventDocs.length - 1)
                ? <hr className="slim-hr"/>
                : null
            }
        </div>
    ));

    const handleAddImgs = (e) => {
        e.preventDefault();

        const targetImgs = Array.prototype.slice.call(e.target.files);
        const imgFiles = [...eventImages];
        targetImgs.some((file) => {
            // Check if file does not already exist in files list
            if (imgFiles.findIndex((f) => f.name === file.name) === -1
                && imgFiles.findIndex((f) => cleanDisplayFilename(f.name) === file.name) === -1) {
                    if (file.type === "image/png" ||
                        file.type === "image/jpg" ||
                        file.type === "image/jpeg") {
                        imgFiles.push(file);
                    } else {
                        alert("Please provide a valid image file");
                    }
            } else {
                alert(`The image entitled ${file.name} is already included in the list`);
            }
            return null;
        })

        setEventImages(imgFiles);
        e.target.value = '';
    }

    const handleRemoveImg = (e, index) => {
        e.preventDefault();

        const imgFiles = [...eventImages];
        const deletedImgName = imgFiles[index].name;
        imgFiles.splice(index, 1);

        setEventImages(imgFiles);

        // Check if deleted CV is already a stored file or a temporary addition
        if (deletedImgName.includes("uploads/events/"))
            setDeleteEventImages([...deleteEventImages, deletedImgName]);
        
        e.target.value = '';
    }

    const imgsList = Array.from(eventImages).map((file, index) => (
        <div key={file.name}>
            <Row>
                <Col sm={9} style={{ padding: '0px 32px' }}>
                    <table><tbody>
                        <tr>
                            <td>
                                <span>
                                    <b>Image name: </b>
                                    <a  href={URL.createObjectURL(file)}
                                        title={cleanDisplayFilename(file.name)}
                                        target="_blank"
                                        rel="noreferrer">
                                            {cleanDisplayFilename(file.name)}
                                    </a>
                                </span>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <b>File size: </b>
                                {humanReadableFileSize(file.size)}
                            </td>
                        </tr>
                    </tbody></table>
                </Col>
                <Col sm={3} style={{ padding: '0px', textAlign: 'center' }}>
                    <Button variant="outline-danger"
                            style={{ borderRadius: "15px" }}
                            onClick={e => handleRemoveImg(e, index)}>
                        <FontAwesomeIcon icon={faTrash} />
                    </Button>
                </Col>
            </Row>
            {index !== (eventImages.length - 1)
                ? <hr className="slim-hr"/>
                : null
            }
        </div>
    ));

    const handleClose = () => {
        setModalShow(false);
        setFormFileUploadRef(React.createRef(null));
        setFormImgeUploadRef(React.createRef(null));

        setTitle("");
        setEventStartDate("");
        setEventEndDate("");
        setEventStartTime("");
        setEventEndTime("");
        setAddress("");
        setDescription("");
        setEventImages([]);
        setDeleteEventImages([]);
        setEventDocs([]);
        setDeleteEventDocs([]);
        setLinks([]);
    
        setMediaLink("");
        setDisplayMediaLink("");
    }

    const setDocFilesFromURL = async (files) => {
        if (props.action === "edit") {
            // Set Docs with Files array instead of Paths array
            if (files !== undefined && files.length !== 0) {
                // Create File items based off stored file paths
                const loadedDocs = [...files];
                var docFiles = [];
                loadedDocs.forEach(async (filepath) => { 
                    await createTempFileFromURL(getRessouresUrl()+filepath, filepath)
                    .then((file) => docFiles.push(file))
                    .catch((error) => console.log(error))
                    setEventDocs([...docFiles])
                });
            } else {
                setEventDocs(files)
            }
        }
    }

    const setImgFilesFromURL = async (files) => {
        if (props.action === "edit") {
            // Set Docs with Files array instead of Paths array
            if (files !== undefined && files.length !== 0) {
                // Create File items based off stored file paths
                const loadedImgs = [...files];
                var imgFiles = [];
                loadedImgs.forEach(async (filepath) => { 
                    await createTempFileFromURL(getRessouresUrl()+filepath, filepath)
                    .then((file) => imgFiles.push(file))
                    .catch((error) => console.log(error))
                    setEventImages([...imgFiles])
                });
            } else {
                setEventImages(files)
            }
        }
    }

    useEffect((files) => {
        setDocFilesFromURL(files? event.eventDocs : []);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect((files) => {
        setImgFilesFromURL(files? event.eventImages : []);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const handleShow = () => {
        // Set event attributes in State variables
        setTitle(event.title);
        setEventStartDate(event.eventStartDate);
        setEventEndDate(event.eventEndDate);
        setEventStartTime(event.eventStartTime);
        setEventEndTime(event.eventEndTime);
        setAddress(event.address);
        setDescription(event.description);
        setLinks(event.links);
        // set Docs
        setDocFilesFromURL(event.eventDocs);
        setDeleteEventDocs([]);
        // set Imgs
        setImgFilesFromURL(event.eventImages);
        setDeleteEventImages([]);

        setModalShow(true);
    }

    const handleActionEvent = async e => {
        e.preventDefault();

        let temp;

        if (props.action === "add") {
            temp = {
                title,
                eventStartDate,
                eventEndDate,
                eventStartTime,
                eventEndTime,
                address,
                description,
                links
            }
        } else if (props.action === "edit") {
            temp = {
                _id: props.id,
                title,
                eventStartDate,
                eventEndDate,
                eventStartTime,
                eventEndTime,
                address,
                description,
                links
            }
        } 

        const event = temp;
        let thingData = new FormData();

        if (props.action === "add" || props.action === "edit") {
            thingData.append('event', JSON.stringify(event));

            // Files to upload
            if (eventDocs !== undefined) {
                Array.from(eventDocs).map((doc) => {
                    
                    // If file includes path to file storage, then ignore.
                    // (This means that the file has already been uploaded and stored.)
                    if (doc.name.includes('uploads/events/')){
                        return null;
                    }

                    thingData.append('events', doc, doc.name);
                    return null;
                });
            }

            // List of file (paths) to delete
            if (deleteEventDocs !== undefined) {
                thingData.append('deletedocs', JSON.stringify([...deleteEventDocs]));
            }

            // Images to upload
            if (eventImages !== undefined) {
                Array.from(eventImages).map((img) => {
                    
                    // If file includes path to file storage, then ignore.
                    // (This means that the file has already been uploaded and stored.)
                    if (img.name.includes('uploads/events/')){
                        return null;
                    }

                    thingData.append('events', img, img.name);
                    return null;
                });
            }

            // List of file (paths) to delete
            if (deleteEventImages !== undefined) {
                thingData.append('deleteimgs', JSON.stringify([...deleteEventImages]));
            }

        } else if (props.action === "remove") {
            thingData = props.event._id;
        }

        await actionEvent(props.action, thingData);
        handleClose();
        props.loadAll();
    }

    if (props.action === "add") {
        modal.header = "Add new event";
        modal.title = "";
        modal.description = "";
        modal.button = "Add new event";
        modal.icon = faPlus;
        modal.buttonVariant = "primary";
        modal.size = "xl";

    } else if (props.action === "edit") {
        modal.header = "Edit event information";
        modal.title = "";
        modal.description = "";
        modal.button = "Edit";
        modal.icon = faPencilAlt;
        modal.buttonVariant = "warning";
        modal.size = "xl";

    } else if (props.action === "remove") {
        modal.header = "Delete event";
        modal.title = "Are you sure you want to delete this event?";
        modal.description = "This event will be permanently deleted, this action cannot be undone.";
        modal.button = "Delete";
        modal.icon = faTrash;
        modal.buttonVariant = "danger";
        modal.size = "md";
    }

    const btnVariant = "outline-" + modal.buttonVariant;

    return (
        <>
            <Button variant={btnVariant}
                    onClick={() => handleShow()}>
                {modal.button}&nbsp;&nbsp;
                <FontAwesomeIcon icon={modal.icon} />
            </Button>

            <Modal  show={modalShow}
                    size={modal.size}
                    onHide={handleClose}
                    backdrop="static"
                    aria-labelledby="contained-modal-title-vcenter"
                    centered >
                <form   onSubmit={handleActionEvent} 
                        encType="multipart/form-data" 
                        className="card-style-steel-texture card-modal">
                    <Modal.Header>
                        <Modal.Title id="contained-modal-title-vcenter">
                            {modal.header}
                        </Modal.Title>
                        <CloseButton    aria-label="Hide popup" 
                                        onClick={() => handleClose()} />
                    </Modal.Header>
                    <Modal.Body>
                        {modal.title
                            ? <h5>{modal.title}</h5>
                            : null
                        }
                        {modal.description
                            ? <p>{modal.description}</p>
                            : null
                        }
                        {(props.action === "add" || props.action === "edit")
                            ?
                            <Container className="custom-modal">
                                <div className="modal-container-xlg">
                                    <Col>
                                        <Form.Group className="mb-3">
                                            <Form.Label>
                                                <b>Title</b> <span className='important-text'>*</span>
                                            </Form.Label>
                                            <Form.Control   type="text"
                                                            name="title"
                                                            value={title}
                                                            onChange={e => setTitle(e.target.value)}
                                                            autoFocus required />
                                        </Form.Group>
                                        <Form.Group className="mb-3">
                                            <Form.Label>
                                                <b>Start date</b> <span className='important-text'>*</span>
                                            </Form.Label>
                                            <Form.Control   type="date"
                                                            name="eventStartDate"
                                                            value={eventStartDate}
                                                            onChange={e => setEventStartDate(e.target.value)} 
                                                            required />
                                        </Form.Group>
                                        <Form.Group className="mb-3">
                                            <Form.Label>
                                                <b>End date</b> <span className='important-text'>*</span>
                                            </Form.Label>
                                            <Form.Control   type="date"
                                                            name="eventEndDate"
                                                            value={eventEndDate}
                                                            onChange={e => setEventEndDate(e.target.value)} 
                                                            required />
                                        </Form.Group>
                                        <Form.Group className="mb-3">
                                            <Form.Label>
                                                <b>Start time</b> <span className='important-text'>*</span>
                                            </Form.Label>
                                            <Form.Control   type="time"
                                                            name="eventStartTime"
                                                            value={eventStartTime}
                                                            onChange={e => setEventStartTime(e.target.value)} 
                                                            required />
                                        </Form.Group>
                                        <Form.Group className="mb-3">
                                            <Form.Label>
                                                <b>End time</b> <span className='important-text'>*</span>
                                            </Form.Label>
                                            <Form.Control   type="time"
                                                            name="eventEndTime"
                                                            value={eventEndTime}
                                                            onChange={e => setEventEndTime(e.target.value)} 
                                                            required />
                                        </Form.Group>
                                        <Form.Group className="mb-3">
                                            <Form.Label>
                                                <b>Location address</b> <span className='important-text'>*</span>
                                            </Form.Label>
                                            <Form.Control   type="text"
                                                            name="address"
                                                            value={address}
                                                            onChange={e => setAddress(e.target.value)}
                                                            required />
                                        </Form.Group>
                                        <Form.Group className="mb-3">
                                            <Form.Label>
                                                <b>Description</b> <span className='important-text'>*</span>
                                            </Form.Label>
                                            <Form.Control 
                                                type="text"
                                                as="textarea" 
                                                rows={7}
                                                name="description"
                                                value={description}
                                                onChange={e => setDescription(e.target.value)}
                                                required />
                                        </Form.Group>
                                    </Col>
                                </div>
                                <div className="modal-container">
                                    <Col>
                                        <Form.Group className="mb-3">
                                            <Form.Label><b>Files and Documents</b></Form.Label>
                                            <Form.Control 
                                                type="file"
                                                ref={formFileUploadRef}
                                                name="events"
                                                accept="application/pdf"
                                                onChange={e => handleAddDocs(e)}
                                                multiple />
                                            {/* <br /> */}
                                            <Button variant="outline-primary"
                                                    style={{ width: "100%", borderRadius: "10px" }}
                                                    onClick={e => handleAutomaticFormDocFileInputClick(e)}>
                                                Upload files&nbsp;&nbsp;
                                                <FontAwesomeIcon icon={faUpload} />
                                            </Button>
                                        </Form.Group>
                                        {/* Display added pdf documents (if there are any) */}
                                        {(eventDocs !== undefined && eventDocs.length !== 0)
                                            ? <>{docsList}<hr /></>
                                            : null
                                        }
                                        <Form.Group className="mb-3">
                                            <Form.Label><b>Images</b></Form.Label>
                                            <Form.Control 
                                                type="file"
                                                ref={formImgUploadRef}
                                                name="events"
                                                accept="image/*"
                                                onChange={e => handleAddImgs(e)}
                                                multiple />
                                            {/* <br /> */}
                                            <Button variant="outline-primary"
                                                    style={{ width: "100%", borderRadius: "10px" }}
                                                    onClick={e => handleAutomaticFormImgFileInputClick(e)}>
                                                Upload images&nbsp;&nbsp;
                                                <FontAwesomeIcon icon={faUpload} />
                                            </Button>
                                        </Form.Group>
                                        {/* Display added pdf documents (if there are any) */}
                                        {(eventImages !== undefined && eventImages.length !== 0)
                                            ? <>{imgsList}<hr /></>
                                            : null
                                        }
                                        <Form.Group className="mb-3">
                                            <Row>
                                                <Col sm={12}>
                                                    <Form.Label>
                                                        <b>Links and resources</b>
                                                    </Form.Label>
                                                    <Form.Control 
                                                        type="url" 
                                                        name="mediaLink"
                                                        value={mediaLink}
                                                        placeholder="Resource URL or link *"
                                                        // format
                                                        onChange={e => setMediaLink(e.target.value)} />
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col sm={9}>
                                                    <Form.Control 
                                                        type="text" 
                                                        name="displayMediaLink"
                                                        value={displayMediaLink}
                                                        placeholder="Display text (optional)"
                                                        onChange={e => setDisplayMediaLink(e.target.value)} />
                                                </Col>
                                                <Col sm={3} style={{ padding: '0px', textAlign: 'center' }}>
                                                    <Button 
                                                        variant="outline-primary"
                                                        disabled={mediaLink.length === 0 || !isValidHttpUrl(mediaLink)}
                                                        onClick={e => handleAddLink(e)}>
                                                        {mediaLink.length === 0 || !isValidHttpUrl(mediaLink)
                                                            ? <></>
                                                            : <>Add&nbsp;&nbsp;</>
                                                        }
                                                        <FontAwesomeIcon icon={faPlus} />
                                                    </Button>
                                                </Col>
                                            </Row>
                                            {/* Display added links (if there are any) */}
                                            {(links !== undefined && links.length !== 0)
                                                ? <><br />{linksList}</>
                                                : null
                                            }
                                        </Form.Group>
                                    </Col>
                                </div>
                            </Container>
                            : <></>
                        }
                    </Modal.Body>
                    <Modal.Footer>
                        <Button 
                            variant="secondary"
                            style={{ width: '100px' }}
                            onClick={() => handleClose()}>
                            Close
                        </Button>
                        <Button 
                            variant={modal.buttonVariant}
                            style={{ width: '100px' }}
                            type="submit" >
                            {props.action.charAt(0).toUpperCase().concat(props.action.substr(1))}
                        </Button>
                    </Modal.Footer>
                </form>
            </Modal>
        </>
    );
}

export default memo(EventModal);
