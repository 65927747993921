import React, { Component, memo } from 'react';
import { Tabs, Tab } from 'react-bootstrap';
import ContactMessageCard from './ContactMessageCard';
import { getApiUrl } from '../../../utils/config';
import { getToken } from '../../../session-utils/UserSession';
import CircularLoading from '../../../components/circular-loading/CircularLoading';


async function loadMessages() {
  return fetch(getApiUrl() + "messages/", {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + getToken()
    },
    mode: 'cors',
    cache: 'default',
    credentials: 'omit'

  })
    .then(res => res.json())
    .then(data => { return data.messages })
    .catch(error => console.log(error))
}

function DashboardMessages() {
  const handleLoadMessages = async () => {
    return await loadMessages();
  }

  return <MessagesComponent handleLoadMessages={handleLoadMessages} />
}

class MessagesComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      messages: {
        'pending': [],
        'responded': []
      },
      handleLoadMessages: props.handleLoadMessages,
      isLoaded: false
    }
  }

  sortMessagesByReceptionDateTime = (messages) => {
    return messages.sort((a, b) => (new Date(a.sentOn) > new Date(b.sentOn)) ? -1 : ((new Date(a.sentOn) < new Date(b.sentOn)) ? 1 : 0))
  }

  sortMessagesByStatus = (messages) => {
    // Sort all messages by message date
    const sortedMessages = this.sortMessagesByReceptionDateTime(messages);

    // Create seperate lists for each message status type
    let pendingList = [];
    let responsedList = [];

    // Populate seperate lists based off message status type
    Array.from(sortedMessages).map((message) => {
      if (message.pending) 
        pendingList = [...pendingList, message];
      else 
        responsedList = [...responsedList, message];
      
      return null;
    });

    return {
        'pending': pendingList,
        'responded': responsedList
      };
  }

  componentDidMount() {
    this.state.handleLoadMessages().then(messages => {
      this.setState({ 
        messages: this.sortMessagesByStatus(messages),
        isLoaded: true 
      })
    }).catch(error => console.log(error))
  }

  render() {
    const nbPending = this.state.messages['pending'].length;
    const nbResponded = this.state.messages['responded'].length;
    const nbAll = nbPending + nbResponded;

    return (
      <>
        <h2>Manage received messages</h2>

        {!this.state.isLoaded
          ? <div><CircularLoading /></div>
          : <>
              <Tabs
                defaultActiveKey="all"
                transition
                id="messages-tabs"
                className="mt-4 mb-4"
              >
                <Tab eventKey="all" title={ "All messages ("+ nbAll + ")"}>
                  {nbAll !== 0
                    ? 
                      <>
                        {this.sortMessagesByReceptionDateTime(
                          [...this.state.messages['pending'], 
                          ...this.state.messages['responded']]).map(message => (
                          <ContactMessageCard key={message._id}
                                              data={message}
                                              loadAll={() => this.state.handleLoadMessages().then(messages => this.setState({ messages: this.sortMessagesByStatus(messages) }))} />
                        ))}
                      </>
                    : <span>No messages found</span>
                  }
                </Tab>
                <Tab eventKey="pendingmessages" title={ "Pending messages ("+ nbPending + ")"}>
                  {nbPending !== 0
                    ? 
                      <>
                        {this.state.messages['pending'].map(message => (
                          <ContactMessageCard key={message._id}
                                              data={message}
                                              loadAll={() => this.state.handleLoadMessages().then(messages => this.setState({ messages: this.sortMessagesByStatus(messages) }))} />
                        ))}
                      </>
                    : <span>No pending messages found</span>
                  }
                </Tab>
                <Tab eventKey="reponsedmessages" title={ "Answered messages ("+ nbResponded + ")"}>
                  {nbResponded !== 0
                    ? 
                      <>
                        {this.state.messages['responded'].map(message => (
                          <ContactMessageCard key={message._id}
                                              data={message}
                                              loadAll={() => this.state.handleLoadMessages().then(messages => this.setState({ messages: this.sortMessagesByStatus(messages) }))} />
                        ))}
                      </>
                    : <span>No old messages found</span>
                  }
                </Tab>
              </Tabs>
            </>
        }
      </>
    );
  }

}

export default memo(DashboardMessages);