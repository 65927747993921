import React, { useState, memo } from 'react';
import { getApiUrl } from '../../../utils/config';
import { getToken } from '../../../session-utils/UserSession';
import { Container, Modal, Button, Form, CloseButton } from 'react-bootstrap';
import { faPlus, faPencilAlt, faTrash } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import './../../../assets/css/style.css';
import 'react-awesome-button/dist/styles.css';


async function actionMailingList(action, credentials) {
    if (action === "add") {
        return fetch(getApiUrl() + "mailinglist/join", {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            mode: 'cors',
            cache: 'default',
            body: credentials
        })
        .then(data => data.json())
        .then(data => { return data.subscriber })
        .catch(error => console.log(error))
    } 
    else if (action === "edit") {
        return fetch(getApiUrl() + "mailinglist/" + action, {
            method: 'POST',
            headers: {
                'Authorization': 'Bearer ' + getToken(),
                'Content-Type': 'application/json'
            },
            mode: 'cors',
            cache: 'default',
            body: credentials
        })
        .then(data => data.json())
        .then(data => { return data.subscriber })
        .catch(error => console.log(error))
    } else if (action === "remove") {
        return fetch(getApiUrl() + "mailinglist/unsubscribe", {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            mode: 'cors',
            cache: 'default',
            body: JSON.stringify({ _id: credentials })
        })
        .then(data => data.json())
        .catch(error => console.log(error))
    }
}

function MailingListModal(props) {
    const [modalShow, setModalShow] = useState(false);

    let [email, setEmail] = useState("");
    let [fullname, setFullname] = useState("");

    let subscriber = props.subscriber;
    if (props.subscriber === undefined || props.subscriber === null) {
        // In Addititon Mode
        subscriber = {
            email: "",
            fullname: ""
        }
    } 

    let modal = {
        header: undefined,
        title: undefined,
        description: undefined,
        button: undefined,
        icon: undefined,
        buttonVariant: undefined,
        size: undefined
    };

    const handleClose = () => {
        setModalShow(false);

        setEmail("");
        setFullname("");
    }

    const handleShow = () => {
        // Set subscriber's attributes in State variables
        setEmail(subscriber.email);
        setFullname(subscriber.fullname);

        setModalShow(true);
    }

    const handleActionMailingList = async e => {
        e.preventDefault();

        let reqData;
        
        if (props.action === "add") {
            reqData = {
                "subscriber": {
                    email,
                    fullname
                }
            }
        } else if (props.action === "edit") {
            reqData = {
                "subscriber": {
                    _id: props.id,
                    email,
                    fullname
                }
            }
        } 

        let thingData = JSON.stringify(reqData); 
        if (props.action === "remove") {
            thingData = props.subscriber._id;
        }

        await actionMailingList(props.action, thingData);
        handleClose();
        props.loadAll();
    }

    if (props.action === "add") {
        modal.header = "Add new subscriber to mailing list";
        modal.title = "";
        modal.description = "";
        modal.button = "Add new subscriber";
        modal.icon = faPlus;
        modal.buttonVariant = "primary";
        modal.size = "md";

    } else if (props.action === "edit") {
        modal.header = "Edit subscriber's information";
        modal.title = "";
        modal.description = "";
        modal.button = "";
        modal.icon = faPencilAlt;
        modal.buttonVariant = "warning";
        modal.size = "md";

    } else if (props.action === "remove") {
        modal.header = "Delete subscriber";
        modal.title = "Are you sure you want to delete this subscriber from your mailing list ?";
        modal.description = "This subscriber's information will be permanently deleted, this action cannot be undone.";
        modal.button = "";
        modal.icon = faTrash;
        modal.buttonVariant = "danger";
        modal.size = "md";
    }

    const btnVariant = "outline-" + modal.buttonVariant;

    return (
        <>
            <Button variant={btnVariant}
                    onClick={() => handleShow()}>
                {
                    modal.button
                    ? <>{modal.button}&nbsp;&nbsp;&nbsp;</>
                    : null
                }
                <FontAwesomeIcon icon={modal.icon} />
            </Button>

            <Modal  show={modalShow}
                    size={modal.size}
                    onHide={handleClose}
                    backdrop="static"
                    aria-labelledby="contained-modal-title-vcenter"
                    centered >
                <form   onSubmit={handleActionMailingList} 
                        encType="multipart/form-data" 
                        className="card-style-steel-texture card-modal">
                    <Modal.Header>
                        <Modal.Title id="contained-modal-title-vcenter">
                            {modal.header}
                        </Modal.Title>
                        <CloseButton    aria-label="Hide popup" 
                                        onClick={() => handleClose()} />
                    </Modal.Header>
                    <Modal.Body>
                        {modal.title
                            ? <h5>{modal.title}</h5>
                            : null
                        }
                        {modal.description
                            ? <p>{modal.description}</p>
                            : null
                        }
                        {(props.action === "add" || props.action === "edit")
                            ?
                            <Container>
                                <Form.Group className="mb-3">
                                    <Form.Label>
                                        <b>Email</b> <span className='important-text'>*</span>
                                    </Form.Label>
                                    <Form.Control   type="email"
                                                    name="email"
                                                    value={email}
                                                    onChange={e => setEmail(e.target.value)}
                                                    autoFocus required />
                                </Form.Group>
                                <Form.Group className="mb-3">
                                    <Form.Label>
                                        <b>Full name</b>
                                    </Form.Label>
                                    <Form.Control 
                                        type="text"
                                        name="fullname"
                                        value={fullname}
                                        onChange={e => setFullname(e.target.value)} />
                                </Form.Group>
                            </Container>
                            : <></>
                        }
                    </Modal.Body>
                    <Modal.Footer>
                        <Button 
                            variant="secondary"
                            style={{ width: '100px' }}
                            onClick={() => handleClose()}>
                            Close
                        </Button>
                        <Button 
                            variant={modal.buttonVariant}
                            style={{ width: '100px' }}
                            type="submit" >
                            {props.action.charAt(0).toUpperCase().concat(props.action.substr(1))}
                        </Button>
                    </Modal.Footer>
                </form>
            </Modal>
        </>
    );
}

export default memo(MailingListModal);
