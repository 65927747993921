import React, { memo } from "react";
import './about-us.css';

function DashboardAboutUs() {
  return (
  <div>
    <h1>Dashboard About Us</h1>
  </div>
  );
}

export default memo(DashboardAboutUs);