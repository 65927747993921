import React, { memo } from 'react';
import {    getRessouresUrl, 
            ellipsify, 
            prettyPrintDateTime,
            cleanDisplayFilename } from '../../../utils/config';
import { getApiUrl } from '../../../utils/config';
import { getToken } from '../../../session-utils/UserSession';
import { Button } from 'react-bootstrap';
import {    faArrowRotateLeft, 
            faCheck, 
            faScrewdriverWrench,
            faThumbsUp,
            faCommentDots} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
            

import ReportModal from './ReportModal';
import PdfViewerModal from '../../../components/pdf-viewer/PdfViewerModal';

import './../../../assets/css/style.css';

async function alterReportState(credentials, callback) {
    return fetch(getApiUrl() + "reports/alterstate", {
        method: 'POST',
        headers: {
            'Authorization': 'Bearer ' + getToken(),
            'Content-Type': 'application/json'
        },
        mode: 'cors',
        cache: 'default',
        body: JSON.stringify({ _id: credentials })
    })
    .then(data => { data.json(); callback() })
    .catch(error => console.log(error))
}


const ReportCard = (props) => {
    const report = props.data;

    return (
        <div className="custom-card viewer card-style-steel-texture card-shadow">
            <div className="text-container">
                <div>
                    {/* Required fields */}
                    <p>{report.category === "issues"
                            ? report.pending
                                ?   <span><FontAwesomeIcon icon={faScrewdriverWrench} style={{ color: 'orange' }} />&nbsp;&nbsp;&nbsp;Issue reported</span>
                                :   <span><FontAwesomeIcon icon={faThumbsUp} style={{ color: 'green' }} />&nbsp;&nbsp;&nbsp;Issue resolved</span>
                            : null
                        }
                        {report.category === "improvements"
                            ? report.pending
                                ?   <span><FontAwesomeIcon icon={faCommentDots} style={{ color: 'orange' }} />&nbsp;&nbsp;&nbsp;Improvement requested</span>
                                :   <span><FontAwesomeIcon icon={faThumbsUp} style={{ color: 'green' }} />&nbsp;&nbsp;&nbsp;Improvement delivered</span>
                            : null
                        }
                    </p><br />
                    <span>
                        <b>Issue submitted on: </b>
                        {prettyPrintDateTime(report.creationDate)}
                    </span>
                    {
                        report.closureDate
                        ?   <span>
                                <br />
                                <b>Issue resolved on: </b>
                                {prettyPrintDateTime(report.closureDate)}
                            </span>
                        : null
                    }
                    <br />
                    <h4 className="mt-3">{ report.title }</h4>
                    <span>{ report.description }</span>
                    {
                        report.reportDocs.length !== 0
                        ?   <div key={report._id+report.title+"docs"}>
                                <br />
                                <p><b>Documents & files</b></p>
                                {report.reportDocs.map((doc, index) => (
                                    <div key={cleanDisplayFilename(doc)+index}>
                                        <PdfViewerModal btnPrompt={cleanDisplayFilename(doc)}
                                                        modalPrompt={cleanDisplayFilename(doc)} 
                                                        ressource={getRessouresUrl()+doc}/>
                                        {index !== (report.reportDocs.length-1)
                                            ? <span><br /></span>
                                            : null
                                        }
                                    </div>
                                ))}
                            </div>
                        : null
                    }
                    {
                        report.reportImages.length !== 0
                        ?   <div key={report._id+report.title+"images"}>
                                <br />
                                <p><b>Media</b></p>
                                {report.reportImages.map((img, index) => (
                                    <img    key={cleanDisplayFilename(img)+index}
                                            src={getRessouresUrl()+img} 
                                            alt={cleanDisplayFilename(img)} 
                                            className="image" />
                                ))}
                            </div>
                        : null
                    }
                    {
                        report.links.length !== 0
                        ?   <div>
                                <br />
                                <p>Links and ressources</p>
                                {report.links.map((data, index) => (
                                    <span key={data.link+index}>
                                        <a  target="_blank" 
                                            rel="noreferrer" 
                                            draggable={false}
                                            href={data.link} >
                                                {data.display
                                                    ? data.display
                                                    : ellipsify(data.link)
                                                }
                                        </a>    
                                        {index !== (report.links.length-1)
                                            ? <span>, </span>
                                            : null
                                        }
                                    </span>))}
                            </div>
                        : null
                    }
                </div>
            </div>
            <div className="buttons-container">
                <Button variant="outline-primary"
                        className="mb-3"
                        onClick={() => alterReportState(report._id, props.loadAll)}>
                    {
                        report.pending
                        ? <span>Mark as resolved&nbsp;&nbsp;&nbsp;<FontAwesomeIcon icon={faCheck} /></span>
                        : <span>Restore as pending&nbsp;&nbsp;&nbsp;<FontAwesomeIcon icon={faArrowRotateLeft} /></span>
                    }
                </Button>

                <ReportModal    id={report._id}
                                loadAll={props.loadAll}
                                report={report}
                                action="edit" />

                <ReportModal    id={report._id}
                                loadAll={props.loadAll}
                                report={report}
                                action="remove" />
            </div>
        </div>);

};

export default memo(ReportCard);
