import React, { useState, memo } from 'react';
import { Col, Row, Button, Form, FloatingLabel } from 'react-bootstrap';
import { faPaperPlane } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Snackbar from '@mui/material/Snackbar';
import Slide from '@mui/material/Slide';
import Alert from '@mui/material/Alert';
import  PhoneInput, {
        isPossiblePhoneNumber,
        isValidPhoneNumber  } from 'react-phone-number-input';
import { getApiUrl } from './../../utils/config';

import './../../assets/css/style.css';
import './../../assets/css/phone-input-styling.css';
import './contact-us.css';


async function actionSendContactMessage(credentials) {
  return fetch(getApiUrl() + "messages/send", {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      mode: 'cors',
      cache: 'default',
      body: credentials
  })
  .then(data => data.json())
  .then(data => { return data.contactMessage })
  .catch(error => console.log(error) )
}

function SlideTransition(props) {
  return <Slide {...props} direction="left" />;
}

function ContactUs() {
  const [lastName, setLastName] = useState("");
  const [firstName, setFirstName] = useState("");
  const [email, setEmail] = useState("");
  const [subject, setSubject] = useState("");
  const [organisation, setOrganisation] = useState("");
  const [phonenumber, setPhoneNumber] = useState("");
  const [message, setMessage] = useState("");

  const [phoneNumberError, setPhoneNumberError] = useState("");
  // const [emailError, setEmailError] = useState("");

  const [showSnackbar, setShowSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarVariant, setSnackbarVariant] = useState("success");

  const handleShowSnackbar = () => {
    setShowSnackbar(true);
  };

  const handleHideSnackbar = () => {
    setShowSnackbar(false);
  };

  const handleJoinMailingList = async e => {
    e.preventDefault();

    // Is possible: {value && isPossiblePhoneNumber(value) ? 'true' : 'false'}
    // Is valid: {value && isValidPhoneNumber(value) ? 'true' : 'false'}

    if (!(phonenumber && isPossiblePhoneNumber(phonenumber) && isValidPhoneNumber(phonenumber))) {
      setPhoneNumberError("Invalid phone number, please insert a valid one");
      return;
    } else {
      setPhoneNumberError("");
    }

    // if (!(email && isValidEmail(email))) {
    //   setEmailError("Invalid email address, please insert a valid one");
    //   return;
    // } else {
    //   setEmailError("");
    // }

    let reqData = {
      "contactMessage": {
          lastName,
          firstName,
          email,
          subject,
          organisation,
          phonenumber,
          message
        }
    }

    let thingData = JSON.stringify(reqData);
    // thingData.append('subscriber', JSON.stringify(subscriber));

    const feedback = await actionSendContactMessage(thingData);
    if (feedback === undefined) {
      setSnackbarMessage("A problem occured while sending your message...");
      setSnackbarVariant("error");
    } else {
      setSnackbarMessage("We’ve received your message. We’ll get back to you as soon as possible ! ")
      setSnackbarVariant("success");
      
      setLastName("");
      setFirstName("");
      setEmail("");
      setSubject("");
      setOrganisation("");
      setPhoneNumber("");
      setMessage("");
    }

    handleShowSnackbar();   
}

  return (
      <div>
        <div className="container contact-us-card card-style-steel-texture card-shadow">
        <h2>Contact Us</h2>
        <p className="mt-3 mb-4">We love hearing from you – whether it's a new opportunity, a suggestion to make things even better, a random thought that popped into your head, or a question you need answered. <br /><br />Send us a message, and let’s start the conversation!</p>
        <br />
        <form onSubmit={handleJoinMailingList} className="container">
          <Row className="collapsable-row">
            <Col>
              <Form.Group>
                <FloatingLabel  controlId="floatingInput"
                                label="First Name *"
                                className="mb-3">
                  <Form.Control type="text" 
                                name="firstName"
                                value={firstName}
                                placeholder="First Name"
                                onChange={e => setFirstName(e.target.value)} 
                                required autoFocus />
                </FloatingLabel>
              </Form.Group>
            </Col>
            <Col>
              <Form.Group>
                <FloatingLabel  controlId="floatingInput"
                                label="Last Name *"
                                className="mb-3">
                  <Form.Control type="text" 
                                name="lastName"
                                value={lastName}
                                placeholder="Last Name"
                                onChange={e => setLastName(e.target.value)} 
                                required />
                </FloatingLabel>
              </Form.Group>
            </Col>
          </Row>
          <Row className="collapsable-row">
            <Col>
              <FloatingLabel  controlId="floatingInput"
                              label="Email address *"
                              className="mb-3">
                <Form.Control type="email" 
                              name="email"
                              value={email}
                              placeholder="Email address"
                              onChange={e => setEmail(e.target.value)} 
                              required />
              </FloatingLabel>
            </Col>
          </Row>
          <Row className="collapsable-row">
            <Col>
              <Form.Group>
                {phoneNumberError
                  ? <span style={{paddingLeft: '1rem', display: 'flex', color: 'red', textAlign: 'left'}}>{phoneNumberError}</span>
                  : null
                }
                <FloatingLabel  controlId="floatingInput"
                                label="Phone Number (Optional)"
                                className="mb-3">
                  <PhoneInput id="phonenumber"
                                className="form-control"
                                placeholder="Phone Number (Optional)"
                                value={phonenumber}
                                international
                                defaultCountry="EG"
                                countryCallingCodeEditable={false}
                                onChange={setPhoneNumber} />
                </FloatingLabel>
              </Form.Group>
            </Col>
          </Row>
          <Row className="collapsable-row">
            <Col>
              <Form.Group>
                <FloatingLabel  controlId="floatingInput"
                                label="Organisation (Optional)"
                                className="mb-3">
                  <Form.Control type="text" 
                                name="organisation"
                                value={organisation}
                                placeholder="Organisation"
                                onChange={e => setOrganisation(e.target.value)} /> 
                </FloatingLabel>
              </Form.Group>
            </Col>
          </Row>
          <Row className="collapsable-row">
            <Col>
              <FloatingLabel  controlId="floatingInput"
                              label="Subject (Optional)"
                              className="mb-3">
                <Form.Control type="text" 
                              name="subject"
                              value={subject}
                              placeholder="Subject"
                              onChange={e => setSubject(e.target.value)} />
              </FloatingLabel>
            </Col>
          </Row>
          <Row>
            <Col>
            <FloatingLabel controlId="floatingTextarea2" label="Message *">
                <Form.Control
                  as="textarea"
                  placeholder="Leave your message here"
                  style={{ height: '150px' }}
                  value={message}
                  onChange={e => setMessage(e.target.value)}
                  required />
              </FloatingLabel>
            </Col>
          </Row>
          <Row className="pt-4" style={{ justifyContent: "center" }}>
            <Button className="btn-contact-us"
                    variant=""
                    type="submit">
              <b>Send</b>&nbsp;&nbsp;&nbsp;<FontAwesomeIcon icon={faPaperPlane} />
            </Button>
          </Row>
        </form>
        <br />
      </div>
      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        autoHideDuration={5000}
        TransitionComponent={SlideTransition}
        open={showSnackbar}
        onClose={handleHideSnackbar}
        key="client-notification"
      >
        <Alert
          onClose={handleHideSnackbar}
          severity={snackbarVariant}
        >
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </div>
  );
}

export default memo(ContactUs);
