import React, { Component, memo } from "react";
import TeamCard from './TeamCard';
import TeamModal from './TeamModal';
import { getApiUrl } from './../../../utils/config';
import CircularLoading from './../../../components/circular-loading/CircularLoading';


async function loadTeam() {
  return fetch(getApiUrl() + "team/", {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json'
    },
    mode: 'cors',
    cache: 'default',
    credentials: 'omit'

  })
    .then(res => res.json())
    .then(data => { return data.members })
    .catch(error => console.log(error))
}

function DashboardTeam() {
  const handleLoadTeam = async () => {
    return await loadTeam();
  }

  return <TeamComponent handleLoadTeam={handleLoadTeam} />
}

class TeamComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      members: [],
      handleLoadTeam: props.handleLoadTeam,
      isLoaded: false
    }
  }

  sortMembersByAppearanceOrder = (members) => {
    return members.sort((a, b) => (a.appearanceOrder < b.appearanceOrder) ? -1 : ((a.appearanceOrder < b.appearanceOrder) ? 1 : 0))
  }

  componentDidMount() {
    this.state.handleLoadTeam().then(members => {
      this.setState({ 
        members: this.sortMembersByAppearanceOrder(members), 
        isLoaded: true 
      })
    }).catch(error => console.log(error))
  }

  render() {
    return (
      <>
        <h2>Manage team members</h2>

        {!this.state.isLoaded
          ? <div><CircularLoading /></div>
          :
          <>
            {!this.state.members
              ? <div>No team members found</div>
              : <>
                  <TeamModal  action="add"
                              nbMembers={this.state.members.length+1}
                              loadAll={() => this.state.handleLoadTeam().then(members => this.setState({ members: this.sortMembersByAppearanceOrder(members) }))} />
                  
                  {this.state.members.map(member => (
                    <TeamCard key={member._id}
                              data={member}
                              nbMembers={this.state.members.length}
                              loadAll={() => this.state.handleLoadTeam().then(members => this.setState({ members: this.sortMembersByAppearanceOrder(members) }))} />
                  ))}
                </>
            }
          </>
        }
      </>
    );
  }

}

export default memo(DashboardTeam);