import React, { Component, memo } from 'react';
import TeamCard from './TeamCard';
import { getApiUrl } from './../../utils/config';
import CircularLoading from './../../components/circular-loading/CircularLoading';

class TeamComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      members: [],
      isLoaded: false
    }
  }

  componentDidMount() {
    console.log("----- ", getApiUrl()+'team')
    fetch(getApiUrl() + 'team', {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json'
      },
      mode: 'cors',
      cache: 'default',
      credentials: 'omit'

    })
      .then(res => res.json())
      .then(data => {
        this.setState({ 
          isLoaded: true, 
          members: this.sortMembersByAppearanceOrder(data.members)
        });
      })
      .catch(error => console.log(error))
  }

  sortMembersByAppearanceOrder = (members) => {
    return members.sort((a, b) => (a.appearanceOrder < b.appearanceOrder) ? -1 : ((a.appearanceOrder < b.appearanceOrder) ? 1 : 0))
  }

  render() {
    if (!this.state.isLoaded) {
      return <div><CircularLoading /></div>;
    } else if (this.state.members.length === 0) {
      return <p>No team members found</p>;
    } else {
      return (  <>
                  {this.state.members.map(member => (
                    <TeamCard key={member._id} 
                              data={member} />
                  ))}
                </>);
    }
  }

}

function Team() {
  return (
    <div>
      <h2>Our Research Team</h2>
      <br />
      <TeamComponent />
    </div>
  );
}

export default memo(Team);