import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { Col } from 'react-bootstrap';
import { CenterToScreen } from './hoc';

import './../../assets/css/style.css'

const JumbotronWrapper = (props) => {
	return (
		<Col {...props.col}>
			<div className="container-fluid text-sm-center p-5 jumbotron-wrapper">
				{/* <img src={logo_oranjade} alt="Logo ORANJADE" style={{width: '40%', display: 'block', marginLeft: 'auto', marginRight: 'auto', marginBottom: '30px'}} /> */}
				<h3 className="text-center"><b>{props.title}</b></h3>
				<div style={{ color: '#0056b3' }}>{props.children}</div>
			</div>
		</Col>
	);
};

JumbotronWrapper.propTypes = {
	title: PropTypes.string.isRequired,
	description: PropTypes.string,
	col: PropTypes.object,
};

JumbotronWrapper.defaultProps = {
	description: ``,
	col: { md: '3.5' }
};

export default memo(CenterToScreen(JumbotronWrapper));
