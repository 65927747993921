import React, { Fragment } from 'react';
import { useLocation } from 'react-router-dom';
import { getVisibleRoutes, getAllowedRoutes } from '../session-utils';
import { PublicRoutesConfig } from '../config';
import Header from '../components/navbar';
import Footer from '../components/footer/index';
import MapAllowedRoutes from './MapAllowedRoutes';
import ScrollToTopHelperButton from './ScrollToTopHelperButton';

import '../assets/css/style.css';

function PublicRoutes() {
	const match = useLocation();
	let allowedRoutes = getAllowedRoutes(PublicRoutesConfig);
	let visibleRoutes = getVisibleRoutes(PublicRoutesConfig);

	return (
		<>
			<Fragment>
				<div className="wrapper">
					<Header routes={visibleRoutes} prefix={match.path} />
					<div className="content">
						<MapAllowedRoutes routes={allowedRoutes} basePath="/" isAddNotFound />
						<ScrollToTopHelperButton />
					</div>
					<Footer />
				</div>
			</Fragment>
		</>
	);
}

export default PublicRoutes;
