import React, { memo } from 'react';
import { useHistory } from 'react-router-dom';
import { faArrowRight} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import './homepage.css';

function Dashboard() {

  const history = useHistory();

  const routeChange = (path) => {
      history.push(path);
  }

  return (
    <div>
      <h2>Dashboard</h2>
      <p>
        Select a component to review, add, edit or remove
      </p>

      <div className="mt-5">        
        <div className="dashboard-presentation-cards">             
            <div className="figures-card steel-texture-orange card-shadow prompting-card">
              <div className="mt-3">
                <h3>Manage Team Members</h3>
                <span>
                    <button className="btn-as-link-dashboard" onClick={() => routeChange('m-team')} >
                      View details&nbsp;&nbsp;&nbsp;<FontAwesomeIcon icon={faArrowRight} />
                    </button>
                </span>
              </div>
            </div>

            <div className="figures-card steel-texture-orange card-shadow prompting-card">
              <div className="mt-3">
                <h3>Manage Publications</h3>
                <span>
                    <button className="btn-as-link-dashboard" onClick={() => routeChange('m-publications')} >
                      View details&nbsp;&nbsp;&nbsp;<FontAwesomeIcon icon={faArrowRight} />
                    </button>
                </span>
              </div>
            </div>
            
            <div className="figures-card steel-texture-orange card-shadow prompting-card">
              <div className="mt-3">
                <h3>Manage News Articles</h3>
                <span>
                    <button className="btn-as-link-dashboard" onClick={() => routeChange('m-news')} >
                      View details&nbsp;&nbsp;&nbsp;<FontAwesomeIcon icon={faArrowRight} />
                    </button>
                </span>
              </div>
            </div>
            
            <div className="figures-card steel-texture-orange card-shadow prompting-card">
              <div className="mt-3">
                <h3>Manage Events</h3>
                <span>
                    <button className="btn-as-link-dashboard" onClick={() => routeChange('m-events')} >
                      View details&nbsp;&nbsp;&nbsp;<FontAwesomeIcon icon={faArrowRight} />
                    </button>
                </span>
              </div>
            </div>

            <div className="figures-card steel-texture-orange card-shadow prompting-card">
              <div className="mt-3">
                <h3>Manage Mailing List</h3>
                <span>
                    <button className="btn-as-link-dashboard" onClick={() => routeChange('m-mailing-list')} >
                      View details&nbsp;&nbsp;&nbsp;<FontAwesomeIcon icon={faArrowRight} />
                    </button>
                </span>
              </div>
            </div>
            
            <div className="figures-card steel-texture-orange card-shadow prompting-card">
              <div className="mt-3">
                <h3>Manage Messages</h3>
                <span>
                    <button className="btn-as-link-dashboard" onClick={() => routeChange('m-messages')} >
                      View details&nbsp;&nbsp;&nbsp;<FontAwesomeIcon icon={faArrowRight} />
                    </button>
                </span>
              </div>
            </div>

            <div className="figures-card steel-texture-orange card-shadow prompting-card">
              <div className="mt-3">
                <h3>Manage Reports</h3>
                <span>
                    <button className="btn-as-link-dashboard" onClick={() => routeChange('m-reporting')} >
                      View details&nbsp;&nbsp;&nbsp;<FontAwesomeIcon icon={faArrowRight} />
                    </button>
                </span>
              </div>
            </div>
        </div>
      </div>
    </div>
  );
}

export default memo(Dashboard);