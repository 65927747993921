import React, { useRef, useState, memo } from 'react';
import { useHistory } from 'react-router-dom';
import { Col, Row, Button, Form, FloatingLabel } from 'react-bootstrap';
import {  faSackDollar, 
          faBrain, 
          faChartLine, 
          faPaste, 
          faAngleDoubleRight, 
          faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Snackbar from '@mui/material/Snackbar';
import Slide from '@mui/material/Slide';
import Alert from '@mui/material/Alert';
import { getApiUrl } from './../../utils/config';

import TypewriterEffect from './../../components/typewriter/TypeWriterEffect';
import useScrollTriggeredCountUp from './../../components/scroll-triggered-counter/ScrollTriggeredCounter';

import introVideo from './../../assets/videos/arca_is_here.mp4';
import img_epa from './../../assets/images/partners/epa.png';
import img_idrc from './../../assets/images/partners/idrc.png';
import img_harvard from './../../assets/images/partners/harvard.png';
import img_ipcc from './../../assets/images/partners/ipcc.png';
import img_unfccc from './../../assets/images/partners/unfccc.png';

import './home.css';


async function actionJoinMailingList(credentials) {
  return fetch(getApiUrl() + "mailinglist/join/", {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      mode: 'cors',
      cache: 'default',
      body: credentials
  })
  .then(data => data.json())
  .then(data => { return data.subscriber })
  .catch(error => console.log(error) )
}

function SlideTransition(props) {
  return <Slide {...props} direction="left" />;
}

function Homepage() {

  const refYearsCounter = useRef(null);
  const yearsCount = useScrollTriggeredCountUp(refYearsCounter, (new Date().getFullYear() - 2011));

  const refPublicationsCounter = useRef(null);
  const publicationsCount = useScrollTriggeredCountUp(refPublicationsCounter, (new Date().getFullYear() - 2011)*5);

  const refSeminarsCounter = useRef(null);
  const seminarsCount = useScrollTriggeredCountUp(refSeminarsCounter, (new Date().getFullYear() - 2011)*4);

  const refStudentsCounter = useRef(null);
  const studentsCount = useScrollTriggeredCountUp(refStudentsCounter, (new Date().getFullYear() - 2011)*70);
  
  const [fullname, setFullName] = useState("");
  const [email, setEmail] = useState("");
  
  const [showSnackbar, setShowSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarVariant, setSnackbarVariant] = useState("success");

  const handleShowSnackbar = () => {
    setShowSnackbar(true);
  };

  const handleHideSnackbar = () => {
    setShowSnackbar(false);
  };

  const history = useHistory();

  const routeChange = (path) => {
    history.push(path);
  }

  const handleJoinMailingList = async e => {
    e.preventDefault();

    let reqData = {
        subscriber: {
          email,
          fullname
        }
    }

    let thingData = JSON.stringify(reqData);
    // thingData.append('subscriber', JSON.stringify(subscriber));

    const feedback = await actionJoinMailingList(thingData);
    if (feedback === undefined) {
      setSnackbarMessage("You are already subscribed to ARCA's mailing list");
      setSnackbarVariant("warning");
    } else {
      setSnackbarMessage("Joined ARCA's mailing list successfully ! ")
      setSnackbarVariant("success");
    }

    handleShowSnackbar();

    setEmail("");
    setFullName("");
}

  const headerPrompt = `Advancing\nClimate\nAdaptation\nThrough\nResearch`;

  return (
    <div className="homepage">
      <div className="video-container">
        <video autoPlay loop muted playsInline>
          <source src={introVideo} type="video/mp4" />
        </video>
        <div className="content-video-container-veil"></div>
        <div className="content-video-container">
          <TypewriterEffect textContent={headerPrompt.trim()} />
          <br />
          <Row className="collapsable-row">
            <Col md="auto">
              <button className="btn-discover card-shadow"
                      onClick={() => routeChange('research')}>
                Discover Our Research
              </button>
            </Col>
            <Col md="auto">
              <button className="btn-discover card-shadow"
                      onClick={() => routeChange('publications')}>
                Browse Our Publications
              </button>
            </Col>
          </Row>
        </div>
      </div>
      
      <div className="homepage-container custom-presentation-card">
        {/* PRESENTATION CARD */}
        <div className="container">
          <div className="presentation-card steel-texture-white card-shadow">
            <h2>Who Are We ?</h2>
            <br />
            <p>The <span className="important-title"><b>Alexandria Research Center for Adaptation to Climate Change (ARCA)</b></span> was established in 2011 through a research initiative funded by IDRC-Canada. Guided by values of excellence, diversity, social responsibility, and integrity, ARCA serves as a leading center for integrated climate change adaptation research. We promote knowledge sharing, foster collaborative, policy-driven research, and facilitate the exchange of experiences and best practices to address the challenges of climate change.</p>

            <br />
            <div style={{ display: "flex", justifyContent: "right" }}>
              <p className="important-title">
                <button className="btn-as-link" onClick={() => routeChange('about-us')} >
                  <b>Learn more about us</b>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<FontAwesomeIcon icon={faArrowRight} />
                </button>
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className="paper-texture card-shadow">
        <h2 className="container container-sub-title">
          Our Services
        </h2>
      
        <div className="services-presentation-cards">
          {/* CONSULTING CARD */}
          <div className="presentation-card card-style-steel-texture card-shadow">
            <h4>
              <span className="important-title">
                <FontAwesomeIcon icon={faChartLine} />&nbsp;&nbsp;</span>
                <b>Consulting</b>
            </h4>
            <hr />
            <p>ARCA provides expert consulting on climate change adaptation, offering tailored solutions and hands-on experience to help organizations and governments build resilience through research-driven strategies.</p>
          </div>
          
          {/* CAPACITY BUILDING CARD */}
          <div className="presentation-card card-style-steel-texture card-shadow">
            <h4>
              <span className="important-title">
                <FontAwesomeIcon icon={faBrain} />&nbsp;&nbsp;</span>
                <b>Capacity Building</b>
            </h4>
            <hr />
            <p>ARCA offers specialized capacity building and training programs, providing technical guidance to researchers, organizations, and institutions working on climate change research and adaptation.</p>
          </div>
          
          {/* FUNDING OPPORTUNITIES CARD */}
          <div className="presentation-card card-style-steel-texture card-shadow">
            <h4>
              <span className="important-title">
                <FontAwesomeIcon icon={faSackDollar} />&nbsp;&nbsp;</span>
                <b>Funding Opportunities</b>
            </h4>
            <hr />
            <p>ARCA provides funding opportunities for exceptional applicants with strong academic records (B.Sc. or BA with very good/excellent merits). Selected candidates are offered a 100% tuition fee allowance for their first semester, supporting their pursuit of advanced climate change research and adaptation studies.</p>
          </div>

          {/* ADMISSION CARD */}
          <div className="presentation-card card-style-steel-texture card-shadow">
            <h4>
              <span className="important-title">
                <FontAwesomeIcon icon={faPaste} />&nbsp;&nbsp;</span>
                <b>Admission</b>
            </h4>
            <hr />
            <p>The Institute of Graduate Studies and Research at Alexandria University is accepting applications for the Climate Change and Sustainable Development Master's program. Graduates from diverse fields, including medicine, pharmacy, agriculture, science, engineering, commerce, and literature (geography), are eligible to apply.</p>
          </div>
        </div>
      </div>

      <div className="homepage-container">
        {/* PRESENTATION CARD */}
        <h2 className="container container-sub-title">
          Figures & Stats
        </h2>
        <br />
        
        <div className="services-presentation-cards">             
            {/* YEARS OF EXPERIENCE CARD */}
            <div className="figures-card rough-texture card-shadow">
              <div ref={refYearsCounter}><span className="big">{yearsCount}</span></div>
              <div><p>Years of Experience</p></div>
            </div>

            {/* PUBLICATIONS CARD */}
            <div className="figures-card rough-texture card-shadow">
              <div ref={refPublicationsCounter}><span className="big">{publicationsCount}</span></div>
              <div><p>Publications & Reports</p></div>
            </div>
            
            {/* SEMINAR CARD */}
            <div className="figures-card rough-texture card-shadow">
              <div ref={refSeminarsCounter}><span className="big">{seminarsCount}</span></div>
              <div><p>Seminars & Workshops</p></div>
            </div>
            
            {/* APPRENTICES CARD */}
            <div className="figures-card rough-texture card-shadow">
              <div ref={refStudentsCounter}><span className="big">{studentsCount}</span></div>
              <div><p>Research Scholars</p></div>
            </div>
        </div>
      </div>

      <div className="paper-texture-color" >
        <div className="paper-texture card-shadow">
          <h2 className="container container-sub-title">
            Our Trusted Partners
          </h2>
          <br />

          <div className="services-presentation-cards">             
              <Row className="parteners-cards">
                <Col className="parteners-card">
                  <img className="image" draggable={false} src={img_idrc} alt="IDRC Logo" />
                </Col>
                <Col className="parteners-card">
                  <img className="image" draggable={false} src={img_epa} alt="EPA Logo" />
                </Col>
                <Col className="parteners-card">
                  <img className="image" draggable={false} src={img_harvard} alt="Harvard Logo" />
                </Col>
                <Col className="parteners-card">
                  <img className="image" draggable={false} src={img_ipcc} alt="IPCC Logo"/>
                </Col>
                <Col className="parteners-card">
                  <img className="image" draggable={false} src={img_unfccc} alt="UNFCC Logo" />
                </Col>
              </Row>
          </div>
        </div>
      </div>

      <div className="homepage-container container">
        {/* NEWSLETTER CARD */}
        <div className="">
          <div className="presentation-card prompting-card steel-texture-orange card-shadow">
            <h3>Stay Informed, Stay Impactful</h3>
            <p style={{ maxWidth: "800px" }}>Get exclusive updates on cutting-edge climate research, funding opportunities, expert insights, and news from ARCA. Subscribe now to our newsletter and stay ahead of the curve – no spam, we promise.</p>
            <br />
            <form onSubmit={handleJoinMailingList}>
              <Row className="collapsable-row">
                <Col>
                  <Form.Group>
                    <FloatingLabel  controlId="floatingInput"
                                    label="Email address *"
                                    className="input-mailing-list mb-3">
                      <Form.Control type="email"
                                    className=""
                                    name="email"
                                    value={email}
                                    placeholder="Email address *"
                                    onChange={e => setEmail(e.target.value)} 
                                    required />
                    </FloatingLabel>
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group>
                    <FloatingLabel  controlId="floatingInput"
                                    label="Full Name (Optional)"
                                    className="input-mailing-list mb-3">
                      <Form.Control type="text" 
                                    name="fullname"
                                    value={fullname}
                                    placeholder="Full Name (Optional)"
                                    onChange={e => setFullName(e.target.value)} />
                    </FloatingLabel>
                  </Form.Group>
                </Col>
                <Col>
                  <Button variant="outline-warning"
                          className="btn-join-mailing-list"
                          type="submit">
                    <b>Join</b>&nbsp;&nbsp;&nbsp;<FontAwesomeIcon icon={faAngleDoubleRight} />
                  </Button>
                </Col>
              </Row>
            </form>
            <br />
          </div>
        </div>
      </div>
      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        autoHideDuration={5000}
        TransitionComponent={SlideTransition}
        open={showSnackbar}
        onClose={handleHideSnackbar}
        key="client-notification"
      >
        <Alert
          onClose={handleHideSnackbar}
          severity={snackbarVariant}
        >
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </div>
  );
}

export default memo(Homepage);
