import { useState, memo } from 'react';
import { Modal } from 'react-bootstrap';
import { library } from '@fortawesome/fontawesome-svg-core'
import { faFilePdf } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Viewer } from '@react-pdf-viewer/core';
import { defaultLayoutPlugin } from '@react-pdf-viewer/default-layout';
import { getFilePlugin } from '@react-pdf-viewer/get-file';
import { cleanDisplayFilename } from './../../utils/config';

import '@react-pdf-viewer/core/lib/styles/index.css';
import '@react-pdf-viewer/default-layout/lib/styles/index.css';

import 'bootstrap/dist/css/bootstrap.min.css';
import './../../assets/css/style.css';
import './pdf-viewer.css';

library.add(faFilePdf)

function PdfViewerModal({ btnPrompt, modalPrompt, ressource }) {
    const [show, setShow] = useState(false);
    
    const defaultLayoutPluginInstance = defaultLayoutPlugin();
    
    const getFilePluginInstance = getFilePlugin({
        fileNameGenerator: (file) => {
            // `file.name` is the URL of opened file
            const fileName = file.name.substring(file.name.lastIndexOf('/') + 1);
            const cleanDisplay = cleanDisplayFilename(fileName);
            return `a-copy-of ${cleanDisplay}`;
        }
    });

    return(
        <>
            <button className="btn-as-link-pdf"
                    draggable={false}
                    onClick={() => setShow(true)}>
                <FontAwesomeIcon icon={faFilePdf} />
                &nbsp;&nbsp;{btnPrompt}
            </button>

            <Modal 
                show={show}
                onHide={() => setShow(false)}
                dialogClassName="pdf-viewer-modal"
                fullscreen>
                <Modal.Header style={{ padding: '0.3rem 0.7rem' }} closeButton>
                    {modalPrompt}
                </Modal.Header>
                <Modal.Body className="pdf-viewer-content">
                    <Viewer
                        fileUrl={ressource}
                        plugins={[
                            getFilePluginInstance,
                            defaultLayoutPluginInstance,
                        ]}
                        />
                </Modal.Body>
            </Modal>
        </>
    );

}

export default memo(PdfViewerModal);