import React, { memo } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {    faCalendar, 
            faClock, 
            faMap } from '@fortawesome/free-regular-svg-icons';
import {    getRessouresUrl, 
            ellipsify,
            prettyPrintDuration,
            cleanDisplayFilename } from '../../../utils/config';
import EventModal from './EventModal';
import PdfViewerModal from '../../../components/pdf-viewer/PdfViewerModal';

import './../../../assets/css/style.css';

const EventCard = (props) => {
    const event = props.data;

    return (
        <div className="custom-card viewer card-style-steel-texture card-shadow">
            <div className="text-container">
                <div>
                    {/* Required fields */}
                    <h6 style={{ fontFamily: "BentonSans-Light" }}>
                        {
                            props.type === "currentlyHappening"
                            ? <b>[CURRENTLY HAPPENING]</b>
                            : props.type === "upcoming"
                                ? <b>[UPCOMING]</b>
                                : <b style={{ fontSize: "25px" }} className="important-text">[ARCHIVED]</b>
                            }
                    </h6>
                    <h3>{ event.title }</h3>
                    <hr />
                    <span>
                        <FontAwesomeIcon icon={faCalendar} />
                        &nbsp;&nbsp;&nbsp;{ prettyPrintDuration(event.eventStartDate, event.eventEndDate) }
                    </span>
                    <br />
                    <span>
                        <FontAwesomeIcon icon={faClock} />
                        &nbsp;&nbsp;&nbsp;from { (new Date("2000-01-01 "+event.eventStartTime)).toLocaleTimeString([], {hour: '2-digit', minute: '2-digit'}) } to { (new Date("2000-01-01 "+event.eventEndTime)).toLocaleTimeString([], {hour: '2-digit', minute: '2-digit'}) }
                    </span>
                    <br />
                    <span>
                        <FontAwesomeIcon icon={faMap} />
                        &nbsp;&nbsp;&nbsp;{event.address}
                    </span>
                    <br />
                    <br />
                    {/* Optional fields */}
                    {
                        event.description
                        ?   <>
                                <p>{ event.description }</p>
                            </>
                        : null
                    }
                    {
                        event.eventDocs.length !== 0
                        ?   <div key={event._id+event.title+"docs"}>
                                <br />
                                <p><b>Documents & files</b></p>
                                {event.eventDocs.map((doc, index) => (
                                    <div key={cleanDisplayFilename(doc)+index}>
                                        <PdfViewerModal btnPrompt={cleanDisplayFilename(doc)}
                                                        modalPrompt={cleanDisplayFilename(doc)} 
                                                        ressource={getRessouresUrl()+doc}/>
                                        {index !== (event.eventDocs.length-1)
                                            ? <span><br /></span>
                                            : null
                                        }
                                    </div>
                                ))}
                            </div>
                        : null
                    }
                    {
                        event.eventImages.length !== 0
                        ?   <div key={event._id+event.title+"images"}>
                                <br />
                                <p><b>Media</b></p>
                                {event.eventImages.map((img, index) => (
                                    <img    key={cleanDisplayFilename(img)+index}
                                            src={getRessouresUrl()+img} 
                                            alt={cleanDisplayFilename(img)} 
                                            className="image" />
                                ))}
                            </div>
                        : null
                    }
                    {
                        event.links.length !== 0
                        ?   <div>
                                <br />
                                <p>Links and ressources</p>
                                {event.links.map((data, index) => (
                                    <span key={data.link+index}>
                                        <a  target="_blank" 
                                            rel="noreferrer" 
                                            draggable={false}
                                            href={data.link} >
                                                {data.display
                                                    ? data.display
                                                    : ellipsify(data.link)
                                                }
                                        </a>    
                                        {index !== (event.links.length-1)
                                            ? <span>, </span>
                                            : null
                                        }
                                    </span>))}
                            </div>
                        : null
                    }
                </div>
            </div>
            <div className="buttons-container">
                <EventModal id={event._id}
                            loadAll={props.loadAll}
                            event={event}
                            action="edit" />

                <EventModal id={event._id}
                            loadAll={props.loadAll}
                            event={event}
                            action="remove" />
            </div>
        </div>);

};

export default memo(EventCard);
