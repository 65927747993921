import {
	Homepage,
    Login,
	AboutUs,
	Research,
	Team,
	Publications,
	News,
	Events,
	ContactUs,
} from '../pages';

const PublicRoutesConfig = [
	{
		component: Homepage,
		path: '/',
		title: 'Home',
		tabTitle: 'Home',
		permission: [
		]
	},
    {
		component: Login,
		path: 'login',
		title: 'Login',
		tabTitle: 'Authentification',
		permission: [
		]
	},
	{
		component: AboutUs,
		path: 'about-us',
		title: 'About us',
		tabTitle: 'About Us',
		permission: [
		]
	},
	{
		component: Research,
		path: 'research',
		title: 'Research',
		tabTitle: 'Research',
		permission: [
		]
	},
	{
		component: Team,
		path: 'team',
		title: 'Team',
		tabTitle: 'Team',
		permission: [
		]
	},
	// {
	// 	component: Research,
	// 	path: 'research',
	// 	title: 'Research',
	// 	permission: [
	// 	]
	// },
	{
		component: Publications,
		path: 'publications',
		title: 'Publications',
		tabTitle: 'Publications',
		permission: [
		]
	},
	{
		component: News,
		path: 'news',
		title: 'News',
		tabTitle: 'News',
		permission: [
		]
	},
	{
		component: Events,
		path: 'events',
		title: 'Events',
		tabTitle: 'Events',
		permission: [
		]
	},
	{
		component: ContactUs,
		path: 'contact-us',
		title: 'Contact us',
		tabTitle: 'Contact Us',
		permission: [
		]
	},
];

export default PublicRoutesConfig;