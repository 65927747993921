import React, { memo, Component } from 'react';
import PropTypes from 'prop-types';
import { Link } from "react-router-dom";
import { isLoggedIn } from './../../../session-utils';
import { NavLink } from 'react-router-dom'; 
import {  UncontrolledCollapse,
          NavbarBrand,
          Navbar,
          Nav } from "reactstrap";
import Logout from '../../../session-utils/logout'
import arca_icon_white from './../../../assets/images/logo/logos_minimal_white.png';  

import './web.css';

function showSideButtons () {
  if (document.getElementById("navbar_global") === undefined)
    if (document.getElementById("navbar_global").hidden === false)
      return false;
  return true;
}

class ExtendedNavbar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      collapseClasses: "",
      collapseOpen: false,
      collapsed: false
    };
  }
  
  onEntering = () => {
    this.setState({
      collapsed: true
    });
  };

  onEntered = () => {
    this.setState({
      collapsed: true
    });
  };

  onExiting = () => {
    this.setState({
      collapseClasses: "collapsing-out",
      collapsed: false
    });
  };

  onExited = () => {
    this.setState({
      collapseClasses: "",
      collapsed: false
    });
  };

  render() {
    return (
      <Navbar
        className="navbar-main navbar-transparent navbar-light"
        expand="lg"
        id={this.props.dimClassName+"-nav"}
      >
        <NavbarBrand className="mr-lg-5" to={this.props.homeRedirection} tag={Link}>
          <img src={arca_icon_white} alt="ARCA" id="arca-logo" className="image" title="Homepage"/>
        </NavbarBrand>
        <button className="navbar-toggler" id="navbar_global">
          <span className="navbar-toggler-icon" />
        </button>
        <UncontrolledCollapse
          toggler="#navbar_global"
          navbar
          className={this.state.collapseClasses}
          onEntered={this.onEntered}
          onEntering={this.onEntering}
          onExiting={this.onExiting}
          onExited={this.onExited}
        >
          <Nav className="navbar-links">
            {this.props.routes.map(({ path, title }) => (
              <div key={path}>
                {this.props.currentLocation === (this.props.homeRedirection+path)
                  ? <NavLink className="navbar-link-active" to={`${this.props.prefix}${path}`}> {title} </NavLink>
                  : <NavLink className="navbar-link" to={`${this.props.prefix}${path}`}> {title} </NavLink>
                }
              </div>
            ))}
          </Nav>
        </UncontrolledCollapse>
        {(!this.state.collapsed && showSideButtons() && isLoggedIn())
          ? <Nav className="ml-auto navbar-right">
              <Logout collapsed={this.state.collapsed} />
            </Nav>
          : null
        }
      </Navbar>
    );
  }
}

ExtendedNavbar.propTypes = {
  routes: PropTypes.arrayOf(
    PropTypes.shape({
      path: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired
    })
  ).isRequired,
  prefix: PropTypes.string,
  className: PropTypes.string
};

ExtendedNavbar.defaultProps ={
  prefix: '',
  className: ''
};


export default memo(ExtendedNavbar);
