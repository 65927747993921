import React, { memo } from 'react';
// import { useHistory } from 'react-router-dom';
// import { faArrowRight} from '@fortawesome/free-solid-svg-icons';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {    ellipsify,
            getRessouresUrl,
            cleanDisplayFilename,
            prettyPrintDate, 
            prettyPrintCategory } from './../../utils/config';
import PdfViewerModal from './../../components/pdf-viewer/PdfViewerModal';
// import { getRessouresUrl, ellipsify, cleanDisplayFilename } from './../../utils/config'
// import PdfViewerModal from '../../components/pdf-viewer/PdfViewerModal';

import '@react-pdf-viewer/core/lib/styles/index.css';
import './../../assets/css/style.css';

const PublicationCard = (props) => {
    const publication = props.data;
    const category = props.category;

    // const history = useHistory();

    // const routeChange = (path) => {
    //     history.push(path);
    // }

    return (
        <div className="custom-card viewer card-publication card-shadow card-style-steel-texture">
            <div className="text-container mb-3">
                <div>
                    {/* Required fields */}
                    <h4>{ publication.title }</h4>
                    <hr className="slim-hr" />
                    <span>{ publication.description }</span>
                    <div className="mt-4">
                        <span>
                            <b>Published on: </b>
                            {prettyPrintDate(publication.publicationDate)}
                        </span>
                    </div>
                    {
                        category
                        ?   <div>
                                <span>
                                    <b>Publication category: </b>
                                    {prettyPrintCategory(category)}
                                </span>
                            </div>
                        : null
                    }
                    {/* Optional fields */}
                    {/* {
                        publication.description
                        ? <p>{ publication.description }</p>
                        : null
                    } */}
                    {
                        publication.publicationDocs.length !== 0
                        ?   <div key={publication._id+publication.title+"docs"}>
                                <br />
                                <span><b>{prettyPrintCategory(publication.category) + " documents"}</b></span>
                                {publication.publicationDocs.map((doc, index) => (
                                    <div key={cleanDisplayFilename(doc)+index}>
                                        <PdfViewerModal btnPrompt={cleanDisplayFilename(doc)}
                                                        modalPrompt={cleanDisplayFilename(doc)} 
                                                        ressource={getRessouresUrl()+doc}/>
                                        {index !== (publication.publicationDocs.length-1)
                                            ? <span><br /></span>
                                            : null
                                        }
                                    </div>
                                ))}
                            </div>
                        : null
                    }
                    {/* {
                        publication.publicationImages.length !== 0
                        ?   <div key={publication._id+publication.title+"images"}>
                                <br />
                                <p><b>Media</b></p>
                                {publication.publicationImages.map((img, index) => (
                                    <img    key={cleanDisplayFilename(img)+index}
                                            src={getRessouresUrl()+img} 
                                            alt={cleanDisplayFilename(img)} 
                                            className="image" />
                                ))}
                            </div>
                        : null
                    } */}
                    {
                        publication.links.length !== 0
                        ?   <div>
                                <br />
                                <p>Links and ressources</p>
                                {publication.links.map((data, index) => (
                                    <span key={data.link+index}>
                                        <a  target="_blank" 
                                            rel="noreferrer" 
                                            draggable={false}
                                            href={data.link} >
                                                {data.display
                                                    ? data.display
                                                    : ellipsify(data.link)
                                                }
                                        </a>    
                                        {index !== (publication.links.length-1)
                                            ? <span>, </span>
                                            : null
                                        }
                                    </span>))}
                            </div>
                        : null
                    } 
                {/* <div className="mt-3" style={{ display: "flex", justifyContent: "right" }}>
                    <span>
                        <button className="btn-as-link-themed" onClick={() => routeChange('about-us')} >
                        <b>View publication</b>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<FontAwesomeIcon icon={faArrowRight} />
                        </button>
                    </span>
                </div> */}
                </div>
            </div>
        </div>);

};

export default memo(PublicationCard);
